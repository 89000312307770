/* eslint-disable no-underscore-dangle */
import React, { memo } from "react";
import { css } from "@emotion/react";

import { ReactComponent as Spoon } from "~assets/icons/spoon.svg";

const index = (props) => {
  const { lat, lng, map, stockist, selectedStockist, setSelectedStockist } =
    props;
  const markerLoc = { lat, lng };

  const handleClick = () => {
    if (
      JSON.stringify(selectedStockist?.location) !== JSON.stringify(markerLoc)
    ) {
      setSelectedStockist({ ...stockist, location: markerLoc });
    }

    map.current.setZoom(18);
    map.current.panTo(markerLoc);
  };

  return (
    <>
      <Spoon
        onClick={handleClick}
        css={css`
          display: block;
          position: absolute;
          width: 10px;
          height: auto;
          margin-top: -20px;
          transform: translate3d(-50%, -50%, 0);
          top: 50%;
          left: 50%;
          transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);

          &:hover {
            cursor: pointer;
            transform: translate3d(-50%, -50%, 0) scale(1.2);
            z-index: 50;
          }
        `}
      />

      {selectedStockist?._id === stockist?._id && (
        <article
          css={css`
            position: relative;
            width: 200px;
            height: auto;
            padding: 1rem;
            border-radius: 10px;
            background-color: var(--color-white);
            color: var(--color-black);
          `}
        >
          <h3 className="b2">{stockist.name}</h3>
          <button type="button" onClick={() => setSelectedStockist(null)}>
            Close
          </button>
        </article>
      )}
    </>
  );
};

// We only want marker to re-render if selectedStockist prop changes.
export default memo(
  index,
  (prevProps, nextProps) =>
    prevProps.selectedStockist === nextProps.selectedStockist
);
