/* eslint-disable react/no-danger */
import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { useSiteMetadata, useSanitySEO } from "~hooks";

const SEO = ({
  customDescription,
  customKeywords,
  customTitle,
  noIndex,
  path
}) => {
  const { siteMetadata } = useSiteMetadata();
  const sanitySeo = useSanitySEO();

  let parsedKeywords = ``;

  if (Array.isArray(customKeywords) && customKeywords?.[0]) {
    parsedKeywords = customKeywords.join(`,`);
  } else if (Array.isArray(sanitySeo.keywords) && sanitySeo.keywords?.[0]) {
    parsedKeywords = sanitySeo.keywords.join(`,`);
  }

  const seo = {
    author: siteMetadata?.author,
    mainUrl: sanitySeo?.url || siteMetadata?.siteUrl,
    location: `${sanitySeo?.url || siteMetadata?.siteUrl}${path?.replace(
      /\//g,
      ``
    )}/`,
    title: customTitle || sanitySeo?.title || siteMetadata?.defaultTitle,
    description:
      customDescription ||
      sanitySeo?.description ||
      siteMetadata?.defaultDescription,
    keywords: parsedKeywords || null,
    image: sanitySeo?.shareImage?.asset?.url || siteMetadata?.image,
    language: siteMetadata?.siteLanguage,
    name: siteMetadata?.siteName,
    year: new Date().getFullYear(),
    facebook: siteMetadata?.facebook,
    instagram: siteMetadata?.instagram
  };

  // schema.org in JSONLD format
  // https://developers.google.com/search/docs/guides/intro-structured-data
  // You can fill out the 'author', 'creator' with more data or another type (e.g. 'Organization')

  // Initial breadcrumb list

  if (noIndex) {
    return (
      <Helmet title={seo.title} titleTemplate={sanitySeo.titleTemplate}>
        <meta name="robots" content="noindex" />
      </Helmet>
    );
  }

  return (
    <Helmet title={seo.title} titleTemplate={sanitySeo.titleTemplate}>
      <html lang="en" />
      <meta property="og:type" content="website" />
      {seo.mainUrl && <meta property="og:url" content={seo.mainUrl} />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && <meta name="description" content={seo.description} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.keywords && <meta name="keywords" content={seo.keywords} />}
      {seo.image && <meta name="image" content={seo.image} />}
      {seo.image && <meta property="og:image" content={seo.image} />}
      {seo.image && <meta name="twitter:card" content="summary_large_image" />}
      {seo.image && <meta name="twitter:image" content={seo.image} />}

      {/* Faceboook/ Meta Pixel */}
      <meta
        name="facebook-domain-verification"
        content="munan7ip3thwbk9readqgm07blfu80"
      />

      <script type="text/javascript">
        {`!function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '3274017016259792');
              fbq('track', 'PageView');`}
      </script>

      <noscript>
        {`<img
          height="1"
          width="1"
          style={{ display: 'none' }}
          src="https://www.facebook.com/tr?id=3274017016259792&ev=PageView&noscript=1"
          alt=""
        />`}
      </noscript>
    </Helmet>
  );
};

SEO.defaultProps = {
  customDescription: null,
  customKeywords: null,
  customTitle: null,
  noIndex: false,
  path: null,
  schemaFAQ: null
};

SEO.propTypes = {
  customDescription: PropTypes.string,
  customKeywords: PropTypes.arrayOf(PropTypes.string),
  customTitle: PropTypes.string,
  noIndex: PropTypes.bool,
  path: PropTypes.string,
  schemaFAQ: PropTypes.shape({})
};

export default SEO;
