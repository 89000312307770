import React from "react";
import { css, Global } from "@emotion/react";

export const COLORS = {
  transparent: `transparent`,
  //
  black: `#000000`,
  "dark-grey": `#373737`,
  "mid-grey": `#BCBCBC`,
  "light-grey": `#E2E2E2`,
  white: `#FFFFFF`,
  //
  "choc-mid": `#CCA986`,
  "choc-light": `#FCC89B`,
  "choc-dark": `#5F401F`,
  vanilla: `#FFE2D6`,
  "vanilla-blue": `#80DED8`,
  "vanilla-brown": `#AD5A44`,
  "vanilla-green": `#9CC011`,
  "vanilla-pink": `#FF966A`,
  "vanilla-pink-light": `#FFDFEC`,
  "peanut-butter": `#D6A461`,
  "peanut-red": `#E35205`,
  "peanut-blue": `#003764`,
  mint: `#B0DABE`,
  "mint-brown": `#FFA46E`,
  "mint-dark": `#00767A`,
  "salted-caramel-pink": `#FFBE9F`,
  "salted-caramel-yellow": `#FFE55B`,
  "salted-caramel": `#DE7C00`,
  "espresso-choc": `#E0C09F`,
  "espresso-red": `#D7341E`,
  "espresso-purple": `#642F6C`,
  "raspberry-mid": `#FF9797`,
  "raspberry-light": `#FFCBD4`,
  "raspberry-dark": `#F13F3F`,
  "caramel-choc-red": `#E04403`,
  "caramel-choc-yellow": `#FFA028`,
  "caramel-choc-brown": `#633510`,
  "mango-yellow": `#FFDB68`,
  "mango-blue": `#25ACB9`,
  "mango-orange": `#FF8A53`,
  "milk-choc-pink": `#F6BDEE`,
  "milk-choc-purple": `#7437D2`,
  "milk-choc-brown": `#694125`,
  "cookies-cream": `#FFD7C8`,
  "cookies-dark": `#0749B3`,
  "cookies-light": `#1DB0DE`,
  "macadamia-purple": `#E5D3FF`,
  "macadamia-yellow": `#F6AA18`,
  "macadamia-green": `#2A723D`,
  "almond-light": `#DFC9F6`,
  "almond-dark": `#7437D2`,
  "almond-brown": `#B16337`,
  "caramel-pink": `#FFBE9F`,
  "caramel-red": `#FF4A4A`,
  caramel: `#DE7C00`,
  "wholesale-blue": `#A9D4DA`,
  "choc-hazelnut-cobalt": `#0762C8`,
  "choc-hazelnut-red": `#FA4616`,
  "choc-hazelnut-sky": `#9BCBEB`,
  "banana-blue": `#00AEC7`,
  "peanut-butter-choc-orange": `#FF7F32`,
  "raspberry-vegan-green": `#71CC98`,
  "raspberry-vegan-pink": `#DA1984`
};

/* e.g
  <p css={css`
    color: var(--color-emperor);
    background: var(--color-white);
  `}>
    Coloured Text
  </p>
*/

const Colors = () => (
  <Global
    styles={[
      css`
        :root {
          ${Object.keys(COLORS).map((colorKey) => {
            const color = COLORS[colorKey];

            // e.g --color-black: #000000;
            return `
              --color-${colorKey}: ${color};
            `;
          })}
        }
      `
    ]}
  />
);

export default Colors;
