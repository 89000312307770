/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */

import React from "react";
import { graphql } from "gatsby";
import { css } from "@emotion/react";
import { MEDIA_QUERIES } from "~utils/helpers";
import { Grid, Button, Go, ProductCard } from "~components";
import { GRID_MOBILE_GAP_REM, GRID_GAP_REM } from "~components/_common/Grid";

const ProductGridSection = ({
  data: { backgroundColor, fontColor, heading, body, button, products }
}) => (
  <div id={`${heading.replace(` `, ``)}-section`}>
    <Grid
      _css={css`
        background: ${backgroundColor?.hex || `var(--color-white)`};
        color: ${fontColor?.hex || `var(--color-black)`};

        padding-top: 3.75rem;
        padding-bottom: 3.75rem;

        ${MEDIA_QUERIES.desktop} {
          padding-top: 3rem;
          padding-bottom: 5rem;
        }
      `}
    >
      <header
        css={css`
          grid-column: 1 / -1;
          margin-bottom: 3rem;

          ${MEDIA_QUERIES.desktop} {
            grid-column: span 3;
          }
        `}
      >
        <h2
          className="h2"
          css={css`
            text-transform: uppercase;
            margin-bottom: 2rem;

            ${MEDIA_QUERIES.desktop} {
              margin-bottom: 2.3125rem;
            }
          `}
        >
          {heading}
        </h2>
        <p
          className="b1"
          css={css`
            margin-bottom: 2rem;

            ${MEDIA_QUERIES.desktop} {
              margin-bottom: 2.3125rem;
            }
          `}
        >
          {body}
        </p>
        <Go
          to={button?.link}
          _css={css`
            width: 100%;

            ${MEDIA_QUERIES.desktop} {
              width: auto;
            }
          `}
        >
          <Button text={button?.text} />
        </Go>
      </header>

      <ul
        css={css`
          position: relative;
          width: 100%;
          max-width: 100%;
          grid-column: 1 / -1;
          display: flex;
          flex-wrap: wrap;
          gap: 0 ${GRID_MOBILE_GAP_REM}rem;

          ${MEDIA_QUERIES.desktop} {
            grid-column: 4 / -1;
            display: grid;
            grid-template-columns: repeat(9, 1fr);
            gap: 0 ${GRID_GAP_REM}rem;
          }
        `}
      >
        {products?.[0] &&
          products.map((product) => (
            <li
              key={product.id}
              css={css`
                width: calc(50% - ${GRID_MOBILE_GAP_REM / 2}rem);

                ${MEDIA_QUERIES.tablet} {
                  width: calc(33.3333% - ${GRID_MOBILE_GAP_REM}rem);
                }

                ${MEDIA_QUERIES.desktop} {
                  width: auto;
                  grid-column: span 3;
                }
              `}
            >
              <ProductCard product={product} />
            </li>
          ))}
      </ul>
    </Grid>
  </div>
);

export default ProductGridSection;

export const query = graphql`
  fragment ProductGridSectionFragment on SanityProductGridSection {
    _type

    backgroundColor {
      hex
      title
    }

    fontColor {
      hex
      title
    }

    heading

    body

    button {
      text
      link
    }

    products {
      id
      name
      slug {
        current
      }
      collection {
        name
      }
      description
      primaryColour {
        hex
      }
      secondaryColour {
        hex
      }
      tertiaryColour {
        hex
      }
      isNew
      isVegan
      isDiscontinued
      gridImage {
        altText
        asset {
          gatsbyImageData(
            width: 800
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
        mobileImage {
          asset {
            gatsbyImageData(
              width: 720
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
      gallery {
        altText
        asset {
          gatsbyImageData(
            width: 800
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
        mobileImage {
          asset {
            gatsbyImageData(
              width: 720
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`;
