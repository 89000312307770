/* eslint-disable react/jsx-props-no-spreading */

import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";

const Select = ({
  error,
  label,
  name,
  options,
  register,
  required,
  _css,
  ...rest
}) => {
  const title = name.replace(/([a-z])([A-Z])/g, `$1 $2`);
  let errorMessage;
  if (error?.type === `required`) {
    errorMessage = (
      <p
        className="b2"
        css={css`
          color: var(--color-raspberry-dark);
          margin-bottom: 0.75rem;
        `}
      >
        <span
          css={css`
            text-transform: capitalize;
          `}
        >
          {title}
        </span>
        {` `}
        is required
      </p>
    );
  }

  if (label) {
    return (
      <label htmlFor={name}>
        <span>{label}</span>
        <select
          {...register(name, { required })}
          {...rest}
          css={css`
            ${_css} ${error?.type && `border: 1px solid red;`}
          `}
        >
          {options.map((value) => (
            <option key={value} value={value}>
              {value}
            </option>
          ))}
        </select>
        {errorMessage && errorMessage}
      </label>
    );
  }

  return (
    <>
      <select
        {...register(name, { required })}
        {...rest}
        css={css`
          ${_css} ${error?.type && `border: 1px solid red;`}
        `}
      >
        {options.map((value) => (
          <option key={value} value={value}>
            {value}
          </option>
        ))}
      </select>
      {errorMessage && errorMessage}
    </>
  );
};

export default Select;

Select.propTypes = {
  error: PropTypes.shape({
    type: PropTypes.string
  }),
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string),
  register: PropTypes.func,
  required: PropTypes.bool,
  _css: PropTypes.arrayOf(PropTypes.shape({}))
};

Select.defaultProps = {
  error: {
    type: ``
  },
  label: ``,
  options: [],
  register: () => {},
  required: false,
  _css: []
};
