/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React, { useRef } from "react";

const Square = ({ color = `#000000`, position = [0, 0, 0], size = [1, 1] }) => {
  // const { camera, mouse, scene } = useThree();

  const planeRef = useRef();

  //

  return (
    <group ref={planeRef} position={position}>
      <mesh>
        <planeGeometry args={size} />

        <meshStandardMaterial attach="material" color={color} />
      </mesh>
    </group>
  );
};

export default Square;
