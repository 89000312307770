import React from "react";
import { css, Global } from "@emotion/react";

const Animations = () => (
  <Global
    styles={[
      css`
        @keyframes appear {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }

        @keyframes appear-up {
          0% {
            transform: translate3d(0, 1rem, 0);
            opacity: 0;
          }
          100% {
            transform: translate3d(0, 0, 0);
            opacity: 1;
          }
        }

        @keyframes appear-down {
          0% {
            transform: translate3d(0, -1rem, 0);
            opacity: 0;
          }
          100% {
            transform: translate3d(0, 0, 0);
            opacity: 1;
          }
        }

        @keyframes appear-left {
          0% {
            transform: translate3d(1rem, 0, 0);
            opacity: 0;
          }
          100% {
            transform: translate3d(0, 0, 0);
            opacity: 1;
          }
        }

        @keyframes appear-right {
          0% {
            transform: translate3d(-1rem, 0, 0);
            opacity: 0;
          }
          100% {
            transform: translate3d(0, 0, 0);
            opacity: 1;
          }
        }

        @keyframes float {
          0% {
            transform: translatey(0px);
          }
          50% {
            transform: translatey(-20px);
          }
          100% {
            transform: translatey(0px);
          }
        }

        @keyframes blur-in {
          0% {
            filter: blur(12px);
          }
          100% {
            filter: blur(0);
          }
        }

        @keyframes load-in {
          0% {
            transform: translate3d(-50%, -50%, 0) rotate3d(0, 0, 0, 0)
              rotate3d(0, 0, 0, 0);
          }
          100% {
            transform: translate3d(-50%, -50%, 0) rotate3d(1, 0, 0, 10deg)
              rotate3d(0, 1, 0, 20deg) rotate3d(0, 0, 1, 10deg);
          }
        }

        @keyframes load-in-lean-left {
          0% {
            transform: translate3d(-50%, -50%, 0) rotate3d(0, 0, 0, 0)
              rotate3d(0, 0, 0, 0);
          }
          100% {
            transform: translate3d(-50%, -50%, 0) rotate3d(1, 0, 0, -5deg)
              rotate3d(0, 1, 0, 15deg) rotate3d(0, 0, 1, -20deg);
          }
        }

        @keyframes load-in-lean-right {
          0% {
            transform: translate3d(-50%, -50%, 0) rotate3d(0, 0, 0, 0)
              rotate3d(0, 0, 0, 0);
          }
          100% {
            transform: translate3d(-50%, -50%, 0) rotate3d(1, 0, 0, -10deg)
              rotate3d(0, 1, 0, 12deg) rotate3d(0, 0, 1, 10deg);
          }
        }

        :root {
          --cubic-easing: cubic-bezier(0.215, 0.61, 0.355, 1);
          --animation-appear-in: 1s var(--cubic-easing) appear forwards;
          --animation-appear-up: 1s var(--cubic-easing) appear-up forwards;
          --animation-appear-down: 1s var(--cubic-easing) appear-down forwards;
          --animation-appear-left: 1s var(--cubic-easing) appear-left forwards;
          --animation-appear-right: 1s var(--cubic-easing) appear-right forwards;
          --animation-blur-in: 1s var(--cubic-easing) blur-in forwards;
          --animation-float: 3s ease-in-out infinite;
        }
      `
    ]}
  />
);

export default Animations;
