/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */

import React, { useEffect, useRef, useState } from "react";
import { graphql } from "gatsby";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Grid, PortableText } from "~components";
import { useScroll } from "~hooks";
import { MEDIA_QUERIES } from "~utils/helpers";

const EASING_CUBIC = `cubic-bezier(0.215, 0.61, 0.355, 1)`;

const Article = styled.article`
  grid-column: 1 / -1;

  ${MEDIA_QUERIES.desktop} {
    grid-column: 4 / span 6;
  }

  ${MEDIA_QUERIES.large} {
    grid-column: 5 / span 4;
  }
`;

const ClipTextUpper = ({ backgroundColor, fontColor, text }) => {
  const { scrollTop } = useScroll();
  const [fixed, setFixed] = useState(false);

  const ref = useRef();

  //

  useEffect(() => {
    const { top } = ref?.current?.getBoundingClientRect();

    if (top > 0) {
      setFixed(false);
    } else {
      setFixed(true);
    }
  }, [scrollTop]);

  //

  return (
    <section
      ref={ref}
      css={css`
        width: 100%;
        height: 150vh;
        position: relative;
        z-index: 20;
        background-color: ${backgroundColor};
        color: ${fontColor};
        clip: rect(auto, auto, auto, auto);
        clip-path: inset(0 0 0 0);
      `}
    >
      <div
        css={css`
          transform: translate3d(0, 0, 0);
          backface-visibility: hidden;

          width: 100vw;
          height: 100vh;
          display: flex;
          align-items: center;
          justify-content: center;
          pointer-events: none;

          position: ${fixed ? `fixed` : `relative`};
          ${fixed &&
          `
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
          `}
        `}
      >
        <h2
          className="d2"
          css={css`
            text-align: center;
            text-transform: uppercase;
            white-space: break-spaces;
            padding: 1rem;

            ${MEDIA_QUERIES.desktop} {
              white-space: normal;
            }
          `}
        >
          {text}
        </h2>
      </div>
    </section>
  );
};

const ClipTextLower = ({ fontColor, text }) => {
  const { scrollTop } = useScroll();
  const [fixed, setFixed] = useState(true);

  const ref = useRef();

  //

  useEffect(() => {
    const { top } = ref?.current?.getBoundingClientRect();

    if (top > 0) {
      setFixed(true);
    } else {
      setFixed(false);
    }
  }, [scrollTop]);

  //

  return (
    <section
      ref={ref}
      css={css`
        width: 100%;
        height: 100vh;
        position: relative;
        z-index: 10;
        overflow: hidden;
        clip: rect(auto, auto, auto, auto);
        clip-path: inset(0 0 0 0);
      `}
    >
      <div
        css={css`
          transform: translate3d(0, 0, 0);
          backface-visibility: hidden;

          width: 100vw;
          height: 100vh;
          position: ${(fixed && `fixed`) || `relative`};
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
        `}
      >
        <div
          css={css`
            transition: opacity 0.3s ${EASING_CUBIC};

            position: relative;
          `}
        >
          <h2
            className="d2"
            css={css`
              color: ${fontColor};
              text-align: center;
              text-transform: uppercase;
              white-space: break-spaces;
              padding: 1rem;

              ${MEDIA_QUERIES.desktop} {
                white-space: normal;
              }
            `}
          >
            {text}
          </h2>
        </div>
      </div>
    </section>
  );
};

const BodyContent = ({ body, fontColor }) => (
  <div
    css={css`
      width: 100%;
      position: relative;
      z-index: 10;
      padding-bottom: 4rem;
    `}
  >
    <div
      css={css`
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;

        transition: opacity 0.3s ${EASING_CUBIC};
        color: ${`var(--color-black)`};
        padding-bottom: 0;
      `}
    >
      <Grid>
        <Article>{body && <PortableText blocks={body} />}</Article>
      </Grid>
    </div>
  </div>
);

/** ============================================================================
 * @component
 * Clippable text scroll section container.
 */
const StickyBanner = ({
  data: {
    primaryColor,
    secondaryColor,
    primaryHeading,
    secondaryHeading,
    _rawBody
  }
}) => {
  const ref = useRef();

  //

  return (
    <div
      ref={ref}
      css={css`
        min-height: 300vh;
        position: relative;
        background-color: ${secondaryColor?.hex || `var(--color-white)`};
      `}
    >
      <ClipTextUpper
        backgroundColor={primaryColor?.hex || `var(--color-white)`}
        fontColor={secondaryColor?.hex || `var(--color-black)`}
        text={primaryHeading}
      />

      <ClipTextLower
        fontColor={primaryColor?.hex || `var(--color-black)`}
        text={secondaryHeading}
      />

      <BodyContent
        fontColor={primaryColor?.hex || `var(--color-black)`}
        body={_rawBody}
      />
    </div>
  );
};

export default StickyBanner;

export const query = graphql`
  fragment StickyBannerFragment on SanityStickyBanner {
    _type

    primaryColor {
      hex
      title
    }

    secondaryColor {
      hex
      title
    }

    primaryHeading
    secondaryHeading

    _rawBody
  }
`;
