import React from "react";
import { createPortal } from "react-dom";

const DOCUMENT_ROOT =
  typeof document !== `undefined` ? document.getElementById(`___gatsby`) : null;

const BreadcrumbsSchema = ({ url, location, collection }) => {
  const itemListElement = [
    {
      "@type": `ListItem`,
      item: {
        "@id": url,
        name: `Homepage`
      }
    }
  ];

  const levels = location
    .split(`/`)
    .slice(3)
    .filter((char) => !!char);

  levels.forEach((level) => {
    const title = level
      .split(`-`)
      .map((word) => {
        const newWord = word.split(``);
        newWord[0] = newWord[0].toUpperCase();
        return newWord.join(``);
      })
      .join(` `);

    if (level === `products` && collection) {
      const collectionUrl = `${url}/collections/${collection.slug.current}`;
      const productUrl = `${url}/products/`;

      const productBreadCrumbs = [
        {
          "@type": `ListItem`,
          item: {
            "@id": collectionUrl,
            name: collection.name
          }
        },
        {
          "@type": `ListItem`,
          item: {
            "@id": productUrl,
            name: title
          }
        }
      ];
      itemListElement.push(...productBreadCrumbs);

      return;
    }

    itemListElement.push({
      "@type": `ListItem`,
      item: {
        "@id": location,
        name: title
      }
    });
  });

  itemListElement.forEach((item, index) => {
    item.position = index + 1;
  });

  const breadcrumbs = {
    "@context": `http://schema.org`,
    "@type": `BreadcrumbList`,
    description: `Breadcrumbs list`,
    name: `Breadcrumbs`,
    itemListElement
  };

  if (DOCUMENT_ROOT) {
    return createPortal(
      <script type="application/ld+json">{JSON.stringify(breadcrumbs)}</script>,
      DOCUMENT_ROOT
    );
  }

  return null;
};

export default BreadcrumbsSchema;
