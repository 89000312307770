/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React, { useState } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Go } from "~components";
import { MEDIA_QUERIES } from "~utils/helpers";

// TODO: use this everywhere CollectionBanner
import { ReactComponent as Sticker } from "~assets/stickers/stockist-sticker.svg";
import { ReactComponent as StickerHover } from "~assets/stickers/stockist-sticker-active.svg";

const StickerContainer = styled.div`
  transition: 0.5s ease filter;

  width: 100%;
  height: 100%;
  position: relative;
  z-index: 10;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  pointer-events: auto;

  ${MEDIA_QUERIES?.hoverable} {
    &:hover {
      cursor: pointer;
    }
  }
`;

const StockistSticker = ({ className, color = `brown` }) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const [hovering, setHovering] = useState(false);

  // ---------------------------------------------------------------------------
  // variables

  let primaryHex = `#ECC3B2`;
  let secondaryHex = `#693F23`;

  switch (color) {
    case `brown`:
      primaryHex = `#693F23`;
      secondaryHex = `#ECC3B2`;
      break;

    case `light-brown`:
      primaryHex = `#ECC3B2`;
      secondaryHex = `#693F23`;
      break;

    default:
      break;
  }

  // ---------------------------------------------------------------------------
  // render

  return (
    <Go to="/stockists" className={className}>
      <StickerContainer
        role="presentation"
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
      >
        <Sticker
          css={css`
            transition: 0.3s var(--cubic-easing) opacity;

            width: 100%;
            position: relative;
            opacity: ${hovering ? `0` : `1`};

            path:nth-child(1) {
              stroke: ${primaryHex};
              fill: ${secondaryHex};
            }

            path:nth-child(2) {
              fill: ${primaryHex};
            }
          `}
        />

        <StickerHover
          css={css`
            transition: 0.3s var(--cubic-easing) opacity;

            width: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
            opacity: ${hovering ? `1` : `0`};
            z-index: 10;

            path:nth-child(1) {
              fill: ${primaryHex};
            }

            path:nth-child(2) {
              fill: ${secondaryHex};
            }
          `}
        />
      </StickerContainer>
    </Go>
  );
};

export default StockistSticker;
