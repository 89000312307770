import React from "react";
import { css } from "@emotion/react";

const index = ({ text, _css, type }) => {
  //
  // styles

  let padding = {
    top: `0.4378rem`,
    bottom: `0.5rem`,
    left: `0.75rem`,
    right: `0.75rem`
  };

  let textClass = `tag`;

  if (type === `small`) {
    padding = {
      top: `0.3125rem`,
      bottom: `0.3125rem`,
      left: `0.4375rem`,
      right: `0.4375rem`
    };

    textClass = `tag-small`;
  }

  return (
    <div
      css={css`
        background-color: transparent;
        color: var(--color-black);
        border: 1px solid var(--color-black);
        padding-top: ${padding.top};
        padding-bottom: ${padding.bottom};
        padding-left: ${padding.left};
        padding-right: ${padding.right};

        position: inline-block;
        width: max-content;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 999px;
        text-transform: uppercase;
        transition: transform 0.3s var(--cubic-easing),
          color 0.3s var(--cubic-easing), background 0.3s var(--cubic-easing),
          opacity 0.3s var(--cubic-easing);
        ${_css}
      `}
    >
      <p className={textClass}>{text}</p>
    </div>
  );
};

export default index;
