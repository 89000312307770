import React from "react";
import PropTypes from "prop-types";
import { Form } from "~components";

const FormEmail = ({ _css, children }) => {
  const onSubmit = async ({ data, reset }) => {
    if (!window || window.location.href.includes(`localhost:8000`)) {
      reset();
      return;
    }

    await fetch(`/api/sendgrid`, {
      body: JSON.stringify(data),
      headers: new Headers({
        "Content-Type": `application/json`
      }),
      method: `POST`
    })
      .then((res) => res.json())
      .catch((error) => {
        console.error(error);
      });
    reset();
  };

  return (
    <Form _css={_css} onSubmit={onSubmit}>
      {children}
    </Form>
  );
};

FormEmail.defaultProps = {
  _css: {}
};

FormEmail.propTypes = {
  _css: PropTypes.shape({}),
  children: PropTypes.node.isRequired
};

export default FormEmail;
