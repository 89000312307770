/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React, { useEffect, useRef, useState } from "react";
import { css } from "@emotion/react";
import { useWindowDimensions } from "~hooks";

// =============================================================================
// core runnable parent

const Canvas = ({ background }) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const canvasRef = useRef();
  const [canvas, setCanvas] = useState({});

  const { windowSize } = useWindowDimensions();

  // ---------------------------------------------------------------------------
  // variables

  // ---------------------------------------------------------------------------
  // methods

  useEffect(() => {
    if (canvas?.node || !canvasRef?.current) {
      return;
    }

    setCanvas({
      ...canvas,
      node: canvasRef.current
    });
  }, [canvasRef?.current]);

  useEffect(() => {
    if (!windowSize?.width) {
      return;
    }

    const { width, height } = windowSize;

    setCanvas({
      ...canvas,
      width,
      height
    });
  }, [windowSize]);

  // ---------------------------------------------------------------------------
  // lifecycle

  //

  // ---------------------------------------------------------------------------
  // render

  return (
    <section
      css={css`
        position: relative;
      `}
    >
      <canvas
        ref={canvasRef}
        width={canvas?.width || 0}
        height={canvas?.height || 0}
        css={css`
          background: ${background};
        `}
      />
    </section>
  );
};

export default Canvas;
