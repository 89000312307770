import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import lottie from "lottie-web";

const LottieAnimation = ({
  id,
  animation,
  animationPublicURL,
  enterFrameCallback,
  completeCallback,
  animationOptions,
  delay,
  startLoading
}) => {
  const ref = useRef();

  const [loaded, setLoaded] = useState(false);

  const init = () => {
    setTimeout(async () => {
      let lottieAnimation;

      if (animation) {
        lottieAnimation = lottie.loadAnimation({
          container: ref.current,
          animationData: animation,
          renderer: `svg`,
          loop: false,
          autoplay: true,
          ...animationOptions
        });
        lottieAnimation.addEventListener(`enterFrame`, () => {
          enterFrameCallback();
        });

        lottieAnimation.addEventListener(`complete`, () => {
          completeCallback();
        });
      } else if (animationPublicURL) {
        fetch(animationPublicURL)
          .then((response) => response.json())
          .then((lottieJSON) => {
            lottieAnimation = lottie.loadAnimation({
              container: ref.current,
              animationData: lottieJSON,
              renderer: `svg`,
              loop: false,
              autoplay: true,
              ...animationOptions
            });

            lottieAnimation.addEventListener(`enterFrame`, () => {
              enterFrameCallback();
            });

            lottieAnimation.addEventListener(`complete`, () => {
              completeCallback();
            });
          });
      } else {
        throw new Error(
          `LottieAnimation needs either animation object or publicURL to fetch animation object`
        );
      }
    }, delay);
  };

  useEffect(() => {
    if (startLoading && !loaded && ref.current) {
      init();
      setLoaded(true);
    }
  }, [startLoading, ref]);

  return <div id={id} ref={ref} />;
};

LottieAnimation.propTypes = {
  animation: PropTypes.shape({}),
  animationPublicURL: PropTypes.string,
  id: PropTypes.string.isRequired,
  style: PropTypes.shape({}),
  animationOptions: PropTypes.shape({}),
  enterFrameCallback: PropTypes.func,
  completeCallback: PropTypes.func,
  delay: PropTypes.number,
  startLoading: PropTypes.bool
};

LottieAnimation.defaultProps = {
  enterFrameCallback: () => {},
  completeCallback: () => {},
  animationOptions: {},
  style: {},
  animation: null,
  animationPublicURL: null,
  delay: 0,
  startLoading: true
};

export default LottieAnimation;
