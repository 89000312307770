import { graphql, useStaticQuery } from "gatsby";

const useSanityGlobals = () => {
  const { sanityGlobals } = useStaticQuery(graphql`
    query Globals {
      sanityGlobals {
        menuLinks {
          _key
          text
          url
          hoverColour {
            hex
          }
        }
        footerLinks {
          _key
          text
          url
        }
      }
    }
  `);

  return { sanityGlobals };
};

export default useSanityGlobals;
