import React from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useApp } from "~hooks";
import { Grid, Go } from "~components";
import { MEDIA_QUERIES } from "~utils/helpers";

import { ReactComponent as Logo } from "~assets/icons/denada-logo.svg";

/** ============================================================================
 * @component
 * Global nav.
 */

//
// CSS

// todo : move somewhere useful (utils/animations?)
const EASING_CUBIC = `cubic-bezier(0.215, 0.61, 0.355, 1)`;

const Wrapper = styled.header`
  transition: color 0.3s ${EASING_CUBIC}, background-color 0.3s ${EASING_CUBIC};
  animation: var(--animation-appear-down);

  width: 100%;
  height: 3rem;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  background-color: var(--color-black);
  color: var(--color-black);

  ${MEDIA_QUERIES.desktop} {
    height: 4rem;
  }
`;

const Container = styled.div`
  grid-column: 1 / -1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

//
// JSX

const Header = () => {
  const { menuActive, setMenuActive } = useApp();

  return (
    <Wrapper>
      <Grid node="nav">
        <Container>
          <Go to="/" onClick={() => setMenuActive(false)}>
            <Logo
              css={css`
                width: 95px;

                ${MEDIA_QUERIES.desktop} {
                  width: 104px;
                }
              `}
              fill="white"
            />
          </Go>

          {/* <button
            type="button"
            onClick={() => setMenuActive(!menuActive)}
            css={css`
              display: flex;
            `}
          >
            {menuActive ? (
              <Cross
                css={css`
                  width: 1.5rem;

                  ${MEDIA_QUERIES.desktop} {
                    width: 2rem;
                  }
                `}
                fill="white"
                stroke="white"
              />
            ) : (
              <Hamburger
                css={css`
                  width: 1.5rem;

                  ${MEDIA_QUERIES.desktop} {
                    width: 2rem;
                  }
                `}
                fill="white"
              />
            )}
          </button> */}
        </Container>
      </Grid>
    </Wrapper>
  );
};

export default Header;
