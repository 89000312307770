/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */

import React, { useEffect, useRef } from "react";
import TubGLTF from "./loader/gltf";

const ROTATION_THRESHOLD_LOWER = 0;
const ROTATION_THRESHOLD_UPPER = 0.5;
const FULL_TUB_ROTATION = Math.PI * 2;

const HomeTub = ({
  progress,
  position = [0, 0, 0],
  rotation = [0, 0, 0],
  scale = [1, 1, 1]
}) => {
  const tubRef = useRef();

  useEffect(() => {
    if (!tubRef?.current) {
      return;
    }

    tubRef.current.rotation.y = 0;
  }, [tubRef?.current]);

  useEffect(() => {
    if (!tubRef?.current) {
      return;
    }

    // rotate
    if (progress < ROTATION_THRESHOLD_LOWER) {
      tubRef.current.rotation.y = 0;
      return;
    }
    if (progress > ROTATION_THRESHOLD_UPPER) {
      tubRef.current.rotation.y = FULL_TUB_ROTATION;
      return;
    }

    const rotateY =
      (progress / (ROTATION_THRESHOLD_UPPER - ROTATION_THRESHOLD_LOWER)) *
      FULL_TUB_ROTATION;

    tubRef.current.rotation.y = rotateY;
  }, [progress]);

  useEffect(() => {
    if (progress < ROTATION_THRESHOLD_LOWER) {
      return;
    }
    if (progress > ROTATION_THRESHOLD_UPPER) {
      return;
    }

    // scale
    // todo : tweak start scale etc.
    const lowerThreshold = scale[0] / 2;
    let targetScale = scale[0] * (1.25 - progress);

    if (targetScale < lowerThreshold) {
      targetScale = lowerThreshold;
      return;
    }

    if (targetScale > scale[0]) {
      targetScale = scale[0];
      return;
    }

    tubRef.current.scale.x = targetScale;
    tubRef.current.scale.y = targetScale;
    tubRef.current.scale.z = targetScale;
  }, [progress]);

  //
  // render

  return (
    <>
      <group ref={tubRef} position={position} rotation={rotation} scale={scale}>
        <TubGLTF />
      </group>
    </>
  );
};

export default HomeTub;
