import { graphql, useStaticQuery } from "gatsby";

const useStanityProducts = () => {
  const { allSanityProduct } = useStaticQuery(graphql`
    query Products {
      allSanityProduct {
        nodes {
          id
          name
          slug {
            current
          }
          collection {
            name
          }
          description
          primaryColour {
            hex
          }
          secondaryColour {
            hex
          }
          tertiaryColour {
            hex
          }
          isNew
          isVegan
          isDiscontinued
          gridImage {
            altText
            asset {
              gatsbyImageData(
                width: 800
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
            mobileImage {
              asset {
                gatsbyImageData(
                  width: 720
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          gallery {
            altText
            asset {
              gatsbyImageData(
                width: 800
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
            mobileImage {
              asset {
                gatsbyImageData(
                  width: 720
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
  `);

  return { allSanityProduct };
};

export default useStanityProducts;
