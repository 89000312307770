/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */

import React, { useState } from "react";
import { graphql } from "gatsby";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

import { Grid, Accordion } from "~components";

import { MEDIA_QUERIES } from "~utils/helpers";

const Wrapper = styled.div`
  padding-top: 3rem;
  padding-bottom: 3.75rem;

  ${MEDIA_QUERIES.desktop} {
    padding-top: 3.75rem;
    padding-bottom: 5rem;
  }
`;

const Heading = styled.h2`
  grid-column: 1 / -1;
  margin-bottom: 2.5rem;
  text-transform: uppercase;

  ${MEDIA_QUERIES.desktop} {
    grid-column: 1 / span 6;
  }
`;

const FaqSection = ({
  data: { backgroundColor, fontColor, heading, items }
}) => {
  const { isDesktop } = useBreakpoint();

  return (
    <Wrapper>
      <Grid>
        <Heading className={isDesktop ? `h1` : `h2`}>{heading}</Heading>

        <Accordion
          items={items}
          bodyHalf
          _css={css`
            grid-column: 1 / -1;

            color: ${fontColor?.hex || `var(--color-black)`};

            ${MEDIA_QUERIES.desktop} {
              grid-column: 7 / span 6;
            }
          `}
        />
      </Grid>
    </Wrapper>
  );
};

export default FaqSection;

export const query = graphql`
  fragment FaqSectionFragment on SanityFaqSection {
    _type

    backgroundColor {
      hex
      title
    }

    fontColor {
      hex
      title
    }

    heading

    items {
      _key
      heading
      _rawText
      text {
        _type
        children {
          _key
          text
          _type
        }
      }
    }
  }
`;
