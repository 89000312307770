import React from "react";
import { css, Global } from "@emotion/react";
import { MEDIA_QUERIES } from "~utils/helpers";

import AntiqueOliveStdWOFF2 from "~assets/fonts/AntiqueOliveStd-Nord.woff2";
import AntiqueOliveStdWOFF from "~assets/fonts/AntiqueOliveStd-Nord.woff";
import AntiqueOliveStdTTF from "~assets/fonts/AntiqueOliveStd-Nord.ttf";
import AntiqueOliveStdEOT from "~assets/fonts/AntiqueOliveStd-Nord.eot";
import MonumentGroteskMediumWOFF2 from "~assets/fonts/MonumentGrotesk-Medium.woff2";
import MonumentGroteskMediumWOFF from "~assets/fonts/MonumentGrotesk-Medium.woff";
import MonumentGroteskMediumTTF from "~assets/fonts/MonumentGrotesk-Medium.ttf";
import MonumentGroteskMediumEOT from "~assets/fonts/MonumentGrotesk-Medium.eot";

const Fonts = () => (
  <Global
    styles={[
      css`
        @font-face {
          font-family: "Antique Olive Std";
          src: url(${AntiqueOliveStdWOFF2}) format("woff2"),
            url(${AntiqueOliveStdWOFF}) format("woff"),
            url(${AntiqueOliveStdTTF}) format("truetype"),
            url(${AntiqueOliveStdEOT}) format("embedded-opentype");
          font-display: block;
          font-weight: bold;
          font-style: normal;
        }

        @font-face {
          font-family: "Monument Grotesk Medium";
          src: url(${MonumentGroteskMediumWOFF2}) format("woff2"),
            url(${MonumentGroteskMediumWOFF}) format("woff"),
            url(${MonumentGroteskMediumTTF}) format("truetype"),
            url(${MonumentGroteskMediumEOT}) format("embedded-opentype");
          font-display: block;
          font-weight: medium;
          font-style: normal;
        }

        .a1 {
          font-family: "Antique Olive Std";
          font-size: 25vw;
          line-height: 1.25;
          letter-spacing: -0.01em;
        }

        .d1 {
          font-family: "Antique Olive Std";
          font-size: 40px;
          line-height: 1em;
          letter-spacing: -0.03em;
        }

        .d2 {
          font-family: "Antique Olive Std";
          font-size: 30px;
          line-height: 0.9em;
          letter-spacing: -0.02em;
          text-transform: uppercase;
        }

        .d3 {
          font-family: "Antique Olive Std";
          font-size: 60px;
          line-height: 1em;
          letter-spacing: -0.06em;
        }

        .h1 {
          font-family: "Antique Olive Std";
          font-size: 50px;
          line-height: 1em;
          letter-spacing: -0.02em;
          text-transform: uppercase;
        }

        .h2 {
          font-family: "Antique Olive Std";
          font-size: 30px;
          line-height: 1em;
          letter-spacing: -0.02em;
          text-transform: uppercase;
        }

        .b1 {
          font-family: "Monument Grotesk Medium";
          font-size: 17px;
          line-height: 1.25em;
          letter-spacing: 0em;
        }

        .b2 {
          font-family: "Monument Grotesk Medium";
          font-size: 15px;
          line-height: 1.25em;
          letter-spacing: 0em;
        }

        .button {
          font-family: "Monument Grotesk Medium";
          font-size: 14px;
          line-height: 0.9em;
          letter-spacing: 0.06em;
          text-transform: uppercase;
        }

        .tag {
          font-family: "Monument Grotesk Medium";
          font-size: 12px;
          line-height: 1em;
          letter-spacing: 0.06em;
        }

        .tag-small {
          font-family: "Monument Grotesk Medium";
          font-size: 10px;
          line-height: 0.84em;
          letter-spacing: 0.02em;
        }

        ${MEDIA_QUERIES.tablet} {
          .d1 {
            font-size: 112px;
            line-height: 1em;
            letter-spacing: -0.025em;
          }

          .d2 {
            font-size: 40px;
            line-height: 0.95em;
            letter-spacing: -0.02em;
          }

          .h1 {
            font-size: 32px;
            line-height: 1em;
            letter-spacing: -0.02em;
          }

          .h2 {
            font-size: 24px;
            line-height: 1em;
            letter-spacing: -0.01em;
          }

          .b1 {
            font-size: 17px;
            line-height: 1.25em;
          }

          .b2 {
            font-size: 15px;
            line-height: 1.25em;
          }
        }

        ${MEDIA_QUERIES.desktop} {
          .d1 {
            font-size: 140px;
            line-height: 1em;
            letter-spacing: -0.03em;
          }

          .d2 {
            font-size: 100px;
            line-height: 0.9em;
            letter-spacing: -0.02em;
          }

          .h1 {
            font-size: 50px;
            line-height: 1em;
            letter-spacing: -0.02em;
          }

          .h2 {
            font-size: 30px;
            line-height: 1em;
            letter-spacing: -0.02em;
          }

          .b1 {
            font-size: 17px;
            line-height: 1.25em;
            letter-spacing: 0em;
          }

          .b2 {
            font-size: 15px;
            line-height: 1.25em;
            letter-spacing: 0em;
          }
        }
      `
    ]}
  />
);

export default Fonts;
