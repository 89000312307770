/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React, { useRef } from "react";
import { useFrame } from "@react-three/fiber";
import { a } from "@react-spring/three";

const Box = ({
  color = `#000000`,
  position = [0, 0, 0],
  scale = [1, 1, 1],
  r = 0.5
}) => {
  // const { camera, mouse, scene } = useThree();

  const boxRef = useRef();

  useFrame((state) => {
    if (!boxRef?.current) {
      return;
    }

    boxRef.current.rotation.x += 0.005 * r;
    boxRef.current.rotation.y += 0.004 * r;
    boxRef.current.rotation.z += 0.006 * r;

    boxRef.current.position.y =
      position[1] +
      Math[r > 0.5 ? `cos` : `sin`](state.clock.getElapsedTime() * r) * r;
  });

  //

  return (
    <group ref={boxRef} position={position} scale={scale}>
      <a.mesh castShadow receiveShadow>
        <boxGeometry attach="geometry" />

        <meshStandardMaterial attach="material" color={color} />
      </a.mesh>
    </group>
  );
};

export default Box;
