/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */

import React from "react";
import { graphql } from "gatsby";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { Grid } from "~components";

import { MEDIA_QUERIES } from "~utils/helpers";

const HeadingSection = ({ data: { backgroundColor, fontColor, heading } }) => {
  const Heading = styled.h1`
    color: ${fontColor?.hex ? fontColor.hex : `var(--color-black)`};
    grid-column: 2 / span 10;
    text-align: center;
    text-transform: uppercase;
  `;

  return (
    <Grid
      _css={css`
        padding: 4rem 1.25rem 5rem;
        background: ${backgroundColor?.hex
          ? backgroundColor.hex
          : `var(--color-white)`};

        ${MEDIA_QUERIES.desktop} {
          padding: 6.25rem 17rem 7.5rem;
        }
      `}
    >
      <Heading className="d3">{heading}</Heading>
    </Grid>
  );
};

export default HeadingSection;

export const query = graphql`
  fragment HeadingSectionFragment on SanityHeadingSection {
    _type

    backgroundColor {
      hex
      title
    }

    fontColor {
      hex
      title
    }

    heading
  }
`;
