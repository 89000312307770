/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React, { useEffect, useRef, useState } from "react";
import { css } from "@emotion/react";

const InfiniteScrollLoop = ({ children, render, surroundingBackup = 2 }) => {
  const contentRef = useRef();
  const scrollRef = useRef();

  const [height, setHeight] = useState(0);
  const [lastScrollTop, setLastScrollTop] = useState(0);

  //

  const backupHeight = height * surroundingBackup;

  //

  useEffect(() => {
    if (!contentRef?.current) {
      return;
    }

    setHeight(contentRef.current.offsetHeight);

    scrollRef.current.scrollTop = backupHeight;
  }, [contentRef]);

  //

  const handleRefScroll = () => {
    const scroll = scrollRef.current.scrollTop;

    setLastScrollTop(scroll);

    if (scroll < backupHeight || scroll >= backupHeight + height) {
      const newScrollTop = backupHeight + (scroll % height);

      scrollRef.current.scrollTop = newScrollTop;
    }
  };

  //

  return (
    <>
      <div
        css={
          [
            // tw`w-full h-screen relative`
          ]
        }
      >
        <div
          ref={scrollRef}
          css={[
            css`
              height: 100%;
              overflow-y: scroll;
              scrollbar-width: none;

              &::-webkit-scrollbar {
                display: none;
              }
            `
          ]}
          onScroll={handleRefScroll}
        >
          {Array(surroundingBackup)
            .fill()
            .map(() => (
              <div>{children}</div>
            ))}

          <div ref={contentRef}>{children}</div>

          {Array(surroundingBackup)
            .fill()
            .map(() => (
              <div>{children}</div>
            ))}
        </div>
      </div>

      {render({
        height,
        scrollTop: lastScrollTop
      })}
    </>
  );
};

export default InfiniteScrollLoop;
