import { graphql, useStaticQuery } from "gatsby";

const useSanityGlobals = () => {
  const { sanitySeo } = useStaticQuery(graphql`
    query SEO {
      sanitySeo {
        url
        title
        titleTemplate
        description
        keywords
        shareImage {
          asset {
            url
          }
        }
      }
    }
  `);

  return sanitySeo;
};

export default useSanityGlobals;
