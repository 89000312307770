/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */

import React from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Button, Grid, StockistSticker, Go } from "~components";
import { MEDIA_QUERIES } from "~utils/helpers";

import lilNada from "~assets/images/lil-nadas-home.png";

import { ReactComponent as HomeSugarSticker } from "~assets/stickers/home-sugar-sticker.svg";

/** --------------------------------------------------------------------------
 * @css
 */

const Container = styled.section`
  width: 100%;
  position: relative;
  z-index: 20;
  display: block;
  background: #ecc3b2;

  ${MEDIA_QUERIES.desktop} {
    height: calc(100vh - 4rem);
    min-height: 720px;
  }
`;

const TubContainer = styled.div`
  grid-column: -1 / 1;
  order: 2;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem 0;

  ${MEDIA_QUERIES.desktop} {
    grid-column: span 6 / span 6;
    height: 100%;
    order: 1;
    padding: 0;
  }
`;

const TubAndAnimation = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 70%;
`;

const TubImg = styled.img`
  width: 100%;
  transform: rotate(-10deg);

  ${MEDIA_QUERIES.desktop} {
    margin-left: 3rem;
  }
`;

const NadaAnimation = styled.div`
  width: 22vw;
  position: absolute;
  bottom: -2rem;
  left: -2rem;
  z-index: 20;
  display: block;

  ${MEDIA_QUERIES.desktop} {
    width: 40%;
  }
`;

//

const ContentContainer = styled.div`
  grid-column: -1 / 1;
  height: 100%;
  position: relative;
  order: 1;

  ${MEDIA_QUERIES.desktop} {
    grid-column: span 5 / span 5;
    grid-column-start: 8;
    order: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const ContentMaxHeight = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 770px;
  position: relative;
`;

const Heading = styled.div`
  width: 100%;
  display: block;
  margin-top: 2rem;
  text-align: center;

  ${MEDIA_QUERIES.desktop} {
    margin-top: 0;
    text-align: left;
  }
`;

const StockistStickerDesktop = styled.div`
  display: none;

  ${MEDIA_QUERIES.desktop} {
    display: block;

    transform: rotate(10deg);

    width: 180px;
    height: 180px;
    position: absolute;
    top: 1rem;
    right: 0;
    z-index: 20;
  }
`;
const StockistStickerMobile = styled.div`
  transform: rotate(10deg);
  width: 120px;
  height: 120px;
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 20;

  ${MEDIA_QUERIES.desktop} {
    display: none;
  }
`;

const SugarStickerDesktop = styled.div`
  display: none;

  ${MEDIA_QUERIES.desktop} {
    display: block;

    transform: rotate(9deg);

    width: 228px;
    position: absolute;
    bottom: 2rem;
    right: 0;
    z-index: 20;
  }
`;
const SugarStickerMobile = styled.div`
  transform: rotate(9deg);

  width: 165px;
  position: absolute;
  bottom: 2rem;
  right: 0;
  z-index: 20;

  ${MEDIA_QUERIES.desktop} {
    display: none;
  }
`;

/** --------------------------------------------------------------------------
 * @component TODO: doc
 */
const LilNadas = () => (
  <Container>
    <Grid
      _css={css`
        height: 100%;
      `}
    >
      <TubContainer>
        <StockistStickerMobile>
          <StockistSticker />
        </StockistStickerMobile>

        <SugarStickerMobile>
          <HomeSugarSticker
            css={css`
              width: 100%;
              position: relative;
              display: block;
            `}
          />
        </SugarStickerMobile>
        <TubAndAnimation>
          <TubImg src={lilNada} alt="Lil Nada’s Tub" />

          <NadaAnimation>
            <img
              css={css`
                width: 100%;
                position: relative;
                display: block;
              `}
              src="/images/lil-nada.gif"
              alt="Happy Lil Nada"
            />
          </NadaAnimation>
        </TubAndAnimation>
      </TubContainer>

      <ContentContainer>
        <ContentMaxHeight>
          <StockistStickerDesktop>
            <StockistSticker />
          </StockistStickerDesktop>

          <Heading>
            <h1 className="d3">
              INTRODUCING
              <br />
              LIL NADA’S
            </h1>
          </Heading>
          <Go to="/collections/lil-nadas">
            <Button
              _css={css`
                width: 100%;
                margin-top: 2rem;
                margin-left: 0;

                ${MEDIA_QUERIES.tablet} {
                  width: auto;
                  margin: 2rem auto;
                }

                ${MEDIA_QUERIES.desktop} {
                  margin: 3rem 0 0;
                }
              `}
              text="Yes please"
            />
          </Go>

          <SugarStickerDesktop>
            <HomeSugarSticker
              css={css`
                width: 100%;
                position: relative;
                display: block;
              `}
            />
          </SugarStickerDesktop>
        </ContentMaxHeight>
      </ContentContainer>
    </Grid>
  </Container>
);

export default LilNadas;
