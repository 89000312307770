/* eslint-disable react/prop-types */

import React, { useEffect, useRef, useState } from "react";
import { PropTypes } from "prop-types";
import axios from "axios";
import { css } from "@emotion/react";
import { validateEmail } from "~utils/helpers";

const NewsletterForm = ({ lambda, listId, render }) => {
  // ===========================================================================
  // context / ref / state

  const submitRef = useRef();

  const [form, setForm] = useState({
    email: ``
  });
  const [focused, setFocused] = useState(null);
  const [status, setStatus] = useState({
    submitted: false,
    submitting: false
  });
  const [valid, setValid] = useState(null);

  // ===========================================================================
  // methods

  const submitProxy = () => {
    if (!submitRef?.current) {
      return;
    }

    submitRef.current.click();
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (
      status?.submitting ||
      status?.submitted ||
      form?.email === null ||
      form?.email === `` ||
      !valid
    ) {
      return false;
    }

    setStatus({
      submitted: false,
      submitting: true
    });

    if (
      typeof window === `undefined` ||
      window.location.href.includes(`localhost:8`)
    ) {
      setTimeout(() => {
        setStatus({
          submitted: true,
          submitting: false
        });
      }, 3000);

      return false;
    }

    const url = `TODO_API/${lambda}`;

    axios({
      method: `post`,
      url,
      headers: {
        Accept: `application/json`,
        "Content-Type": `application/json; charset=utf-8`
      },
      data: JSON.stringify({
        email: form.email,
        listId
      })
    })
      .then((response) => {
        // eslint-disable-next-line no-console
        console.log(response);

        setStatus({
          submitting: false,
          submitted: true
        });
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);

        setStatus({
          submitting: false,
          submitted: false
        });
        return false;
      });

    return false;
  };

  // ===========================================================================
  // lifecycle

  useEffect(() => {
    setValid(form?.email && validateEmail(form.email));
  }, [form]);

  // ===========================================================================
  // render

  return (
    <>
      {listId && (
        <form
          css={
            [
              // tw`w-full relative block`
            ]
          }
          onSubmit={onSubmit}
        >
          <input
            ref={submitRef}
            tw="w-0 h-0 absolute opacity-0 pointer-events-none"
            type="submit"
            hidden
          />

          {render({
            data: form,
            focused,
            setFocused,
            status,
            submit: submitProxy,
            update: setForm,
            valid
          })}
        </form>
      )}
    </>
  );
};

NewsletterForm.propTypes = {
  lambda: PropTypes.string.isRequired,
  listId: PropTypes.string.isRequired,
  render: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired
};

export default NewsletterForm;
