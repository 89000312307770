/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React from "react";
import { css } from "@emotion/react";
import { useWindowDimensions } from "~hooks";

const ScreenHeight = ({ _css, children }) => {
  const { windowSize } = useWindowDimensions();

  //

  return (
    <section
      css={css`
        height: ${windowSize?.height || `100vh`}px;

        ${_css};
      `}
    >
      {children}
    </section>
  );
};

export default ScreenHeight;
