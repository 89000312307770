/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */

import React from "react";
import { graphql } from "gatsby";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { SwiperCarousel } from "~components";
import { MEDIA_QUERIES } from "~utils/helpers";

import { ReactComponent as Spoon } from "~assets/icons/spoon.svg";

//
// CSS

const Wrapper = styled.section`
  height: auto;
  position: relative;

  ${MEDIA_QUERIES.desktop} {
    height: 28.4vw;
    padding-top: 0;
    padding-bottom: 0;
  }

  ${MEDIA_QUERIES.wide} {
    height: 410px;
  }
`;

const Slide = styled.article`
  width: 100%;
  height: 100%;
  padding-top: 10vw;
  padding-bottom: 30vw;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  ${MEDIA_QUERIES.desktop} {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

const SlideText = styled.p`
  padding-left: 2rem;
  padding-right: 2rem;
  margin-bottom: 1rem;
  text-transform: uppercase;

  ${MEDIA_QUERIES.desktop} {
    max-width: 65%;
    padding-left: 0;
    padding-right: 0;
  }
`;

const NavigationButtonContainer = styled.div`
  width: 50%;
  position: absolute;
  bottom: 0;
  padding-bottom: 8vw;
  z-index: 20;
  pointer-events: auto;
  display: flex;
  align-items: center;

  ${MEDIA_QUERIES.desktop} {
    width: 8vw;
    height: 100%;
    top: 0;
    padding-bottom: 0;
  }
`;

const NavigationButton = styled.button`
  width: 5rem;
  display: block;

  ${MEDIA_QUERIES.desktop} {
    width: 100%;
    height: 100%;
  }
`;

const SpoonCSS = css`
  transition: 0.15s ease transform;

  width: 11px;
  height: 54px;
  fill: white;
  stroke: transparent;
`;

//
// JSX

const TextCarousel = ({ data: { carousel } }) => {
  let prevSlide;
  let nextSlide;

  return (
    <Wrapper>
      <SwiperCarousel
        css={css`
          height: 100%;
        `}
        options={{
          autoplay: { delay: 4000 }
        }}
        slides={carousel.map((textSlide) => (
          <Slide
            css={css`
              background: ${textSlide.backgroundColor?.hex
                ? textSlide.backgroundColor.hex
                : `var(--color-white)`};
              color: ${textSlide.fontColor?.hex
                ? textSlide.fontColor.hex
                : `var(--color-black)`};
            `}
          >
            <SlideText className="h1">{textSlide.text}</SlideText>
          </Slide>
        ))}
        nav={({ next, prev }) => {
          prevSlide = prev;
          nextSlide = next;
        }}
      />

      <NavigationButtonContainer
        css={css`
          left: 0;
          justify-content: flex-end;

          ${MEDIA_QUERIES.desktop} {
            justify-content: center;
          }
        `}
      >
        <NavigationButton
          type="button"
          onClick={() => prevSlide && prevSlide()}
          css={css`
            ${MEDIA_QUERIES.hoverable} {
              &:hover {
                svg {
                  transform: translate3d(-100%, 0, 0) rotate(90deg);
                }
              }
            }
          `}
        >
          <Spoon
            css={css`
              ${SpoonCSS};
              transform: rotate(90deg);
            `}
          />
        </NavigationButton>
      </NavigationButtonContainer>

      <NavigationButtonContainer
        css={css`
          right: 0;
          justify-content: flex-start;

          ${MEDIA_QUERIES.desktop} {
            justify-content: center;
          }
        `}
      >
        <NavigationButton
          type="button"
          onClick={() => nextSlide && nextSlide()}
          css={css`
            ${MEDIA_QUERIES.hoverable} {
              &:hover {
                svg {
                  transform: translate3d(100%, 0, 0) rotate(-90deg);
                }
              }
            }
          `}
        >
          <Spoon
            css={css`
              ${SpoonCSS};
              transform: rotate(-90deg);
            `}
          />
        </NavigationButton>
      </NavigationButtonContainer>
    </Wrapper>
  );
};

export default TextCarousel;

//
// GQL

export const query = graphql`
  fragment TextCarouselFragment on SanityTextCarousel {
    _type

    carousel {
      _key
      backgroundColor {
        hex
      }
      fontColor {
        hex
      }
      text
    }
  }
`;
