/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useState } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useInView } from "react-intersection-observer";
import { CollectionBannerWrapper, Image, Marquee } from "~components";

import raspberryTub from "~assets/images/raspberry-ripple-blank.png";
import caramelChocFlakeTub from "~assets/images/caramel-choc-flake.png";
import doubleChocTub from "~assets/images/double-choc.png";
import mintChipTub from "~assets/images/mint-chip.png";
import peanutButterTub from "~assets/images/peanut-butter.png";
import vanillaTub from "~assets/images/vanilla.png";

import { MEDIA_QUERIES } from "~utils/helpers";

import { ReactComponent as Logo } from "~assets/icons/logo.svg";

const ClipPath = styled.div`
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  right: auto;
  bottom: 0;
  left: 25%;
  z-index: 30;
  opacity: 1;
  pointer-events: none;
`;

const TubContainer = styled.div`
  transition: 0.3s ease transform;

  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  clip-path: url(#tub-mask);

  ${MEDIA_QUERIES.hoverable} {
    &:hover {
      transform: scale(1.05);
    }
  }
`;

const CollectionBannerTub = () => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const { ref, inView } = useInView();

  const [flavour, setFlavour] = useState(0);
  const [tubInteraction, setTubInteraction] = useState(false);

  // ---------------------------------------------------------------------------
  // variables

  const flavours = [
    {
      background: `var(--color-raspberry-mid)`,
      logoColor: `var(--color-raspberry-light)`,
      tubImage: raspberryTub
    },
    {
      background: `var(--color-peanut-butter)`,
      logoColor: `var(--color-peanut-red)`,
      tubImage: peanutButterTub
    },
    {
      background: `var(--color-vanilla)`,
      logoColor: `var(--color-vanilla-blue)`,
      tubImage: vanillaTub
    },
    {
      background: `var(--color-caramel-choc-red)`,
      logoColor: `var(--color-caramel-choc-yellow)`,
      tubImage: caramelChocFlakeTub
    },
    {
      background: `var(--color-choc-mid)`,
      logoColor: `var(--color-choc-light)`,
      tubImage: doubleChocTub
    },
    {
      background: `var(--color-mint)`,
      logoColor: `var(--color-mint-brown)`,
      tubImage: mintChipTub
    }
  ];

  // ---------------------------------------------------------------------------
  // render

  return (
    <div ref={ref}>
      <CollectionBannerWrapper
        background={flavours?.[flavour]?.background || `#000000`}
        heading={`SUGAR FREE\nICE-CREAM`}
        inView={inView}
        ctaText="OMFG YES PLEASE"
        url="/collections/ice-cream"
      >
        <div
          css={css`
            animation: float var(--animation-float);

            ${MEDIA_QUERIES.tablet} {
              width: 75%;
            }

            ${MEDIA_QUERIES.desktop} {
              width: 100%;
            }
          `}
        >
          <div
            css={css`
              transition: 2s ease filter;

              filter: blur(${inView ? `0` : `12px`});

              padding-top: 4rem;

              ${MEDIA_QUERIES.tablet} {
                padding-top: 0;
              }
            `}
          >
            <div
              css={css`
                transition: transform 0.15s var(--cubic-easing);

                transform: rotateZ(24.17deg)
                  scale(${tubInteraction ? `0.9` : `1`});
                position: relative;
                cursor: pointer;
                padding: 15% 15% 0;
              `}
            >
              {/* // clip path // */}
              <ClipPath>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1 1">
                  <clipPath
                    id="tub-mask"
                    clipPathUnits="objectBoundingBox"
                    transform="scale(0.0023,0.002)"
                  >
                    <path d="M162.89,0h77.19l40.11.61H296.6l48.62,1.82c18.6,2.85,37.89.7,55,4,4.92.94,10,.93,13.37,3.34,4.55,3.23,2.45,10.29,3.95,16.72L421.2,38.9c1.18,6.33,1.8,38.52-.31,42.24l-16.41,2.43Q387.92,280.77,371.36,478c-1.55,2.41-13.75,2.66-17.93,3.35l-41.64,3-16.1.3-62.91,1.22H188.11c-20.71-.92-42.13,1.83-61.69-1.22l-16.41-.3-41.63-3c-6.74-1.13-12.88-.95-17.93-3.35Q38.14,333.39,25.83,188.72q-3.19-38.59-6.38-77.19c-.88-5.62-.1-25-2.74-28C12.48,82,2,83.85,0,80.23V65.34L.61,38.59l4-14c1.21-6.39-.67-11.86,3.65-14.9C15.39,4.67,28.47,6.21,38.9,4.56c20-3.18,42.47-.07,63.21-3.34l23.4-.61Z" />
                  </clipPath>
                </svg>
              </ClipPath>

              {/* // content // */}
              <TubContainer>
                <Marquee
                  css={css`
                    width: 82%;
                    position: absolute;
                    top: 48%;
                    left: 48.5%;
                    transform: translate3d(-50%, -50%, 0);
                    pointer-events: none;
                  `}
                  speed="4s"
                  direction="left"
                >
                  <Logo
                    css={css`
                      width: 75%;
                    `}
                    fill={flavours[flavour].logoColor}
                  />
                </Marquee>

                <div
                  css={css`
                    width: 100%;
                    user-select: none;
                    pointer-events: auto;
                    cursor: pointer;
                  `}
                  role="presentation"
                  onMouseDown={() => setTubInteraction(true)}
                  onMouseUp={() => {
                    setTubInteraction(false);

                    if (flavour < flavours.length - 1) {
                      setFlavour(flavour + 1);
                    } else {
                      setFlavour(0);
                    }
                  }}
                >
                  <Image
                    image={flavours[flavour].tubImage}
                    css={css`
                      width: 100%;
                    `}
                  />
                </div>
              </TubContainer>
            </div>
          </div>
        </div>
      </CollectionBannerWrapper>
    </div>
  );
};

export default CollectionBannerTub;
