import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { css } from "@emotion/react";
import { Video } from "~components";

/** ============================================================================
 * @component
 * A full-width section container for a Video component.
 */
const VideoSection = ({ data: { backgroundColor, videoURL } }) => (
  <section
    css={[
      css`
        background: white;
      `
      // tw`w-full relative block`
    ]}
  >
    <Video
      css={
        [
          // tw`w-full relative block`
        ]
      }
      src={videoURL}
    />
  </section>
);

export default VideoSection;

VideoSection.defaultProps = {
  data: {
    backgroundColor: [
      {
        hex: `#000000`,
        title: `black`
      }
    ]
  }
};

VideoSection.propTypes = {
  data: PropTypes.shape({
    backgroundColor: PropTypes.shape({
      hex: PropTypes.string,
      title: PropTypes.string
    }),
    videoURL: PropTypes.string.isRequired
  })
};

export const query = graphql`
  fragment VideoSectionFragment on SanityVideoSection {
    _type

    backgroundColor {
      hex
      title
    }

    videoURL
  }
`;
