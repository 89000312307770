/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React, { useRef } from "react";
import { css } from "@emotion/react";
import { Html } from "@react-three/drei";
import { useScroll, useWindowDimensions } from "~hooks";

const BannerText = ({
  color = `#000000`,
  text = `THREED`,
  position = [0, 0, 0]
}) => {
  // const { camera, mouse, scene } = useThree();

  const ref = useRef();
  const { scrollTop } = useScroll();
  const { windowSize } = useWindowDimensions();

  //

  const textTransformY = parseFloat(
    (scrollTop / windowSize?.width) * 50
  ).toFixed(2);

  return (
    <group ref={ref} position={position}>
      <Html fullscreen>
        <h1
          className="d1"
          css={css`
            color: ${color};
            text-align: center;
            white-space: pre-wrap;
            text-transform: uppercase;
            padding-top: 4vw;
            transform: translate3d(0, ${textTransformY}%, 0);
          `}
        >
          {text}
        </h1>
      </Html>
    </group>
  );
};

export default BannerText;
