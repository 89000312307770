import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { Link } from "gatsby";

const Go = ({ _css, children, className, debug, onClick, parameters, to }) => {
  if (!to || to.length === 0) return null;

  // Build the parameter string only if parameters are provided
  const parameterString = parameters
    ? Object.keys(parameters)
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(parameters[key])}`
        )
        .join(`&`)
    : ``;

  // Build the href without adding a trailing slash
  const href = `${to}${parameterString ? `?${parameterString}` : ``}`;

  if (debug) {
    console.log(`Go component href:`, href);
  }

  // Conditionally render Gatsby Link or anchor tag
  return !href.startsWith(`http`) &&
    !href.startsWith(`mailto`) &&
    !href.startsWith(`tel`) ? (
    <Link
      to={href}
      className={className}
      css={css`
        display: inline-block;
        ${_css};
      `}
      onClick={onClick}
    >
      {children}
    </Link>
  ) : (
    <a
      href={href}
      onClick={onClick}
      rel="noopener noreferrer"
      target="_blank"
      className={className}
      css={css`
        display: inline-block;
        ${_css};
      `}
    >
      {children}
    </a>
  );
};

Go.defaultProps = {
  _css: {},
  className: ``,
  debug: false,
  onClick: () => {},
  parameters: null
};

Go.propTypes = {
  _css: PropTypes.shape({}),
  className: PropTypes.string,
  debug: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  parameters: PropTypes.shape({}),
  to: PropTypes.string.isRequired
};

export default Go;
