import React from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

import { MEDIA_QUERIES } from "~utils/helpers";

const MarqueeContainer = styled.div`
  @keyframes marqueeRightToLeft {
    0% {
      transform: translate(100%, 0);
    }
    100% {
      transform: translate(-100%, 0);
    }
  }

  @keyframes marqueeLeftToRight {
    0% {
      transform: translate(-100%, 0);
    }
    100% {
      transform: translate(100%, 0);
    }
  }

  @keyframes marqueeTopToBottom {
    0% {
      transform: translate(0, -100%);
    }
    100% {
      transform: translate(0, 100%);
    }
  }

  @keyframes marqueeBottomToTop {
    0% {
      transform: translate(0, 100%);
    }
    100% {
      transform: translate(0, -100%);
    }
  }

  position: relative;
  width: 100%;
  overflow: hidden;
  user-select: none;
`;

const MarqueeWrapper = styled.div`
  animation: ${({ direction }) => {
      switch (direction) {
        case `left`:
          return `marqueeRightToLeft`;

        case `right`:
          return `marqueeLeftToRight`;

        case `up`:
          return `marqueeBottomToTop`;

        case `down`:
          return `marqueeTopToBottom`;

        default:
          return `marqueeRightToLeft`;
      }
    }}
    ${({ speed }) => speed || `10s`} linear infinite;
  width: 100%;
`;

const index = ({ children, className, direction, speed }) => (
  <MarqueeContainer className={className}>
    <MarqueeWrapper direction={direction} speed={speed}>
      {children}
    </MarqueeWrapper>
  </MarqueeContainer>
);

export default index;
