import React from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { Image, Go, Tag } from "~components";

import { MEDIA_QUERIES } from "~utils/helpers";

const Article = styled.article`
  margin-bottom: 1.5rem;
  max-width: 318px;
`;

const Figure = styled.figure`
  position: relative;
  margin-bottom: 0.75rem;
  border-radius: 1rem;
  overflow: hidden;

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 100%;
    right: 0;
    bottom: 0;
    left: 0;
    transition: top 0.3s var(--cubic-easing);
  }

  &:hover div {
    transform: scale(105%);
  }

  &:hover::after {
    top: 0%;
  }
`;

const renderTags = ({ isNew, isVegan, isDiscontinued, isDesktop }) => {
  const tags = [];

  if (isNew) {
    tags.push(<Tag key="new" text="New" type={!isDesktop && `small`} />);
  }

  if (isVegan) {
    tags.push(<Tag key="vegan" text="Vegan" type={!isDesktop && `small`} />);
  }

  if (isDiscontinued) {
    tags.push(
      <Tag
        key="discontinued"
        text="Discontinued"
        type={!isDesktop && `small`}
      />
    );
  }

  return tags;
};

const index = ({ product }) => {
  const {
    slug,
    primaryColour,
    secondaryColour,
    gridImage,
    name,
    isNew,
    isVegan,
    isDiscontinued
  } = product;

  const { isDesktop } = useBreakpoint();

  const hasTag = isNew || isVegan || isDiscontinued;

  return (
    <Article>
      <Go
        to={`/products/${slug.current}`}
        _css={css`
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
        `}
      >
        <Figure
          css={css`
            background-color: ${primaryColour?.hex || `var(--color-gray)`};

            &::after {
              background-color: ${secondaryColour?.hex};
            }
          `}
        >
          <Image
            image={gridImage}
            css={css`
              z-index: 1;
              transition: transform 0.3s var(--cubic-easing);
            `}
          />
        </Figure>
        {hasTag && (
          <div
            css={css`
              position: absolute;
              top: 0.5rem;
              right: 0.5rem;
              display: flex;
              gap: 0.125rem;
              z-index: 1;

              ${MEDIA_QUERIES.desktop} {
                top: 1rem;
                right: 1rem;
                z-index: 1;
                gap: 0.25rem;
              }
            `}
          >
            {renderTags({ isNew, isVegan, isDiscontinued, isDesktop })}
          </div>
        )}
        <h3 className={isDesktop ? `b1` : `b2`}>{name}</h3>
      </Go>
    </Article>
  );
};

export default index;
