/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React, { Suspense } from "react";
import { css } from "@emotion/react";
import { Canvas } from "@react-three/fiber";
import HomeTub from "~components/_three/models/Tub/HomeTub.jsx";

const HomeBannerTub = ({ progress }) => (
  <div
    css={css`
      width: 100%;
      height: 100%;
      position: relative;
    `}
  >
    <Canvas
      colorManagement
      camera={{ position: [0, 0, 100], fov: 100, zoom: 10 }}
    >
      <ambientLight intensity={0.4} />
      <directionalLight castShadow position={[4, 1, 1]} intensity={1} />
      <directionalLight castShadow position={[-4, -8, -1]} intensity={2} />

      <Suspense fallback={null}>
        <HomeTub
          progress={progress}
          position={[0, -1, 20]}
          rotation={[0, 0, 0]}
          scale={[77, 77, 77]}
        />
      </Suspense>
    </Canvas>
  </div>
);

export default HomeBannerTub;
