/* eslint-disable import/prefer-default-export */
import { portableTextToString } from "~utils/helpers";

export const generateFAQSchema = (questions) => {
  let schemaFAQ = null;
  const mainEntity = [];

  questions.forEach((question) => {
    mainEntity.push({
      "@type": `Question`,
      name: question.heading,
      acceptedAnswer: {
        "@type": `Answer`,
        text: portableTextToString(question.text)
      }
    });
  });

  if (mainEntity.length > 0) {
    schemaFAQ = {
      "@context": `https://schema.org`,
      "@type": `FAQPage`,
      mainEntity
    };
  }
  return schemaFAQ;
};
