import React, { useState, useEffect } from "react";

import { Form } from "~components";

const FormKlaviyo = ({ listId, children, _css }) => {
  const [formResponse, setFormResponse] = useState(null);

  useEffect(() => {
    console.log(`Klavio Form Response`, formResponse);
  }, [formResponse]);

  const onSubmit = async ({ data, reset }) => {
    const formData = { listId, ...data };

    console.log(`Klavio Form Submitted`, formData);

    const response = await fetch(`/api/klaviyo`, {
      body: JSON.stringify(formData),
      headers: new Headers({
        "Content-Type": `application/json`
      }),
      method: `POST`
    })
      .then((res) => res.json())
      .catch((error) => {
        console.error(error);
      });

    setFormResponse(response);
    reset();
  };

  return (
    <Form _css={_css} onSubmit={onSubmit}>
      {children}
    </Form>
  );
};

export default FormKlaviyo;
