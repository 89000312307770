/* eslint-disable react/prop-types */

import React from "react";
import { css } from "@emotion/react";

import { ReactComponent as Star } from "~assets/icons/star.svg";

const ReviewCard = ({ review }) => (
  <article
    css={
      [
        // tw`w-full relative block p-6 rounded-lg bg-neutral-000 border-neutral-100 border text-neutral-100`
      ]
    }
  >
    <div
      css={
        [
          // tw`w-full relative flex`
        ]
      }
    >
      {Array(review.rating)
        .fill(null)
        .map((star, starIndex) => (
          <div key={`${review.id}-${starIndex}`}>
            <Star
              _css={
                [
                  // tw`w-6`
                ]
              }
              color="black"
            />
          </div>
        ))}
    </div>

    <h3
      className="h3"
      _css={
        [
          // tw`mt-2 mb-4`
        ]
      }
    >
      {review?.author || `Review`}
    </h3>

    <p
      className="b1"
      _css={
        [
          // tw`mt-2 mb-4`
        ]
      }
      font="2"
    >
      {review?.content || `Review content paragraph`}
    </p>
  </article>
);

export default ReviewCard;
