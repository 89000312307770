/* eslint-disable consistent-return */
import { useState, useEffect, useRef } from "react";

const initialized = [];

const useGoogleMapsApi = (apiKey) => {
  const [googleApi, setGoogleApi] = useState();

  useEffect(() => {
    if (window.google) {
      // if window.google object is already available just use it
      setGoogleApi(window.google);
      return;
    }

    const src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&v=weekly&libraries=geometry,places`;
    const existingScript = initialized.find((el) => el.src === src);

    const onLoad = () => {
      setGoogleApi(window.google);
    };

    if (existingScript) {
      // if script tag was added by other element just check when it is loaded
      existingScript.addEventListener(`load`, onLoad);
      return;
    }

    const script = document.createElement(`script`);
    script.src = src;
    script.async = true;
    script.defer = true;
    script.addEventListener(`load`, onLoad);
    document.head.appendChild(script);
    initialized.push(script);

    return () => {
      script.removeEventListener(`load`, onLoad);
      script.remove();
      initialized.splice(
        initialized.findIndex((el) => el.src === src),
        1
      );
    };
  }, [apiKey]);

  return googleApi;
};

const useGoogleMaps = (apiKey, options) => {
  const google = useGoogleMapsApi(apiKey);

  const ref = useRef(null);
  const [map, setMap] = useState();

  useEffect(() => {
    if (!google || !ref) {
      return;
    }
    setMap(new google.maps.Map(ref.current, options));
  }, [google, ref]);

  return { ref, map, google };
};

export default useGoogleMaps;
