/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useState } from "react";
import { css } from "@emotion/react";
import { useInView } from "react-intersection-observer";
import { CollectionBannerWrapper, ChocPopsBox } from "~components";
import { MEDIA_QUERIES } from "~utils/helpers";

import { ReactComponent as Sticker } from "~assets/stickers/now-in-a-four-pack.svg";

const CollectionBannerTub = () => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const { ref, inView } = useInView();

  const [flavour, setFlavour] = useState(0);
  const [boxInteraction, setBoxInteraction] = useState(false);

  // ===========================================================================
  // variables

  const flavours = [
    {
      id: `mint`,
      backgroundPrimary: `var(--color-mint-dark)`,
      backgroundSecondary: `var(--color-mint)`
    },
    {
      id: `almond`,
      backgroundPrimary: `var(--color-almond-brown)`,
      backgroundSecondary: `var(--color-milk-choc-pink)`
    },
    {
      id: `caramel`,
      backgroundPrimary: `var(--color-salted-caramel)`,
      backgroundSecondary: `var(--color-milk-choc-pink)`
    },
    {
      id: `hazelnut`,
      backgroundPrimary: `var(--color-choc-hazelnut-cobalt)`,
      backgroundSecondary: `var(--color-cookies-light)`
    }
  ];

  // ---------------------------------------------------------------------------
  // render

  return (
    <div ref={ref}>
      <CollectionBannerWrapper
        // background={flavours?.[flavour]?.backgroundPrimary || `#000000`}
        background={flavours[3]?.backgroundPrimary || `#000000`}
        heading="FOUR THE LOVE OF CHOC POPS"
        inView={inView}
        ctaText="SAY NO MORE"
        url="products/choc-hazelnut/"
        reverse
      >
        <div
          css={css`
            // width: 40%;
            width: 40vw;
            height: 90vw;
            position: relative;

            ${MEDIA_QUERIES.tablet} {
              width: 40vw;
              height: 120vw;
              margin: 0 auto;
              padding-top: 2rem;
            }

            ${MEDIA_QUERIES.desktop} {
              width: 100%;
              height: 100%;
            }
          `}
        >
          <div
            css={css`
              transition: transform 0.3s var(--cubic-easing);

              animation: float var(--animation-float);

              width: 100%;
              height: 100%;
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;

              ${MEDIA_QUERIES.hoverable} {
                &:hover {
                  transform: scale(1.05);
                }
              }
            `}
          >
            <div
              css={css`
                transition: transform 0.15s var(--cubic-easing);

                transform: scale(${boxInteraction ? `0.9` : `1`});

                width: 100%;
                position: relative;
                z-index: 20;
                user-select: none;
                pointer-events: auto;
                cursor: pointer;

                ${MEDIA_QUERIES.desktop} {
                  width: 350px;
                }
              `}
              role="presentation"
              onMouseDown={() => setBoxInteraction(true)}
              onMouseUp={() => {
                setBoxInteraction(false);

                if (flavour < flavours.length - 1) {
                  setFlavour(flavour + 1);
                } else {
                  setFlavour(0);
                }
              }}
            >
              {/* <ChocPopsBox flavour={flavours?.[flavour]} visible={inView} /> */}
              <ChocPopsBox
                flavour={flavours[3]}
                visible={inView}
                loadIn="right"
              />
            </div>
          </div>

          <Sticker
            css={css`
              z-index: 20;
              position: absolute;
              top: 65%;
              right: -25%;
              width: 120px;

              ${MEDIA_QUERIES.tablet} {
                top: 60%;
                width: 220px;
                right: -60%;
              }

              ${MEDIA_QUERIES.desktop} {
                display: none;
              }
            `}
            fill="var(--color-white)"
          />
        </div>
      </CollectionBannerWrapper>
    </div>
  );
};

export default CollectionBannerTub;
