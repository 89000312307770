import React from "react";

import { Form } from "~components";

const FormMailChimp = ({ children, _css }) => {
  const onSubmit = async ({ data, reset }) => {
    console.log(`Mailchimp Form Submitted`, data);

    await fetch(`/api/mailchimp`, {
      body: JSON.stringify(data),
      headers: new Headers({
        "Content-Type": `application/json`
      }),
      method: `POST`
    })
      .then((res) => res.json())
      .catch((error) => {
        console.error(error);
      });

    reset();
  };

  return (
    <Form _css={_css} onSubmit={onSubmit}>
      {children}
    </Form>
  );
};

export default FormMailChimp;
