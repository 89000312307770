/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */

import React from "react";
import { graphql } from "gatsby";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Grid } from "~components";
import { MEDIA_QUERIES } from "~utils/helpers";

const gridCSS = `
min-height: 100vw;
display: flex;
align-items: center;
justify-content: center;
position: relative;
padding-top: 3rem;
padding-bottom: 3.75rem;

${MEDIA_QUERIES.desktop} {
  min-height: 45vw;
  height: 45vw;
  padding-top: 6.25rem;
  padding-bottom: 7.5rem;
}

${MEDIA_QUERIES.wide} {
  min-height: 660px;
  height: 660px;
}
`;

const Heading = styled.h2`
  text-align: center;
  text-transform: uppercase;
  white-space: pre-line;
`;

const TextBanner = ({
  data: { backgroundColor, fontColor, heading, size }
}) => (
  <Grid
    _css={css`
      ${gridCSS};

      background: ${backgroundColor?.hex
        ? backgroundColor.hex
        : `var(--color-white)`};
      color: ${fontColor?.hex ? fontColor.hex : `var(--color-white)`};
    `}
  >
    <Heading className={size === `medium` ? `d3` : `d1`}>{heading}</Heading>
  </Grid>
);

export default TextBanner;

export const query = graphql`
  fragment TextBannerFragment on SanityTextBanner {
    _type

    backgroundColor {
      hex
      title
    }

    fontColor {
      hex
      title
    }

    heading
    size
  }
`;
