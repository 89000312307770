/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */

import React, { useRef } from "react";
import { useFrame } from "@react-three/fiber";
import { useScroll, useWindowDimensions } from "~hooks";
import BoxGLTF from "./loader/gltf";

const ChocPop = ({
  position = [0, 0, 0],
  rotation = [0, 0, 0],
  r = 0.5,
  scale = [1, 1, 1]
}) => {
  const { scrollTop } = useScroll();
  const { windowSize } = useWindowDimensions();

  const tubRef = useRef();

  useFrame(() => {
    if (!tubRef?.current) {
      return;
    }

    tubRef.current.rotation.y += 0.014 * r;

    if (scrollTop < windowSize?.width) {
      tubRef.current.position.y =
        position?.[1] - 2 * (scrollTop / windowSize?.width);
    }
  });

  //

  return (
    <>
      <group ref={tubRef} position={position} rotation={rotation} scale={scale}>
        <BoxGLTF />
      </group>
    </>
  );
};

export default ChocPop;
