/* eslint-disable react/jsx-props-no-spreading */
import React, { Fragment } from "react";

import {
  WebPageSchema,
  OraganizationSchema,
  FAQSchema,
  BreadcrumbsSchema
} from "~components";

import { useSiteMetadata, useSanitySEO } from "~hooks";
import { generateFAQSchema } from "~utils/structuredData";

const StructuredData = ({
  collection,
  customTitle,
  customDescription,
  customKeywords,
  path,
  pageType,
  sections
}) => {
  const { buildTime, siteMetadata } = useSiteMetadata();
  const sanitySeo = useSanitySEO();

  const seo = {
    author: siteMetadata?.author,
    mainUrl: sanitySeo?.url || siteMetadata?.siteUrl,
    location: `${sanitySeo?.url || siteMetadata?.siteUrl}${path}`,
    title: customTitle || sanitySeo?.title || siteMetadata?.defaultTitle,
    description:
      customDescription ||
      sanitySeo?.description ||
      siteMetadata?.defaultDescription,
    image: sanitySeo?.shareImage?.asset?.url || siteMetadata?.image,
    language: siteMetadata?.siteLanguage,
    name: siteMetadata?.siteName,
    year: new Date().getFullYear(),
    facebook: siteMetadata?.facebook,
    instagram: siteMetadata?.instagram
  };

  const jsx = [
    <BreadcrumbsSchema
      url={seo.mainUrl}
      location={seo.location}
      collection={collection}
    />,
    <OraganizationSchema {...seo} />
  ];

  switch (pageType) {
    default:
      jsx.push(<WebPageSchema buildTime={buildTime} {...seo} />);
      break;
  }

  sections.forEach((section) => {
    switch (section._type) {
      case `faqSection`:
        {
          const faqSchema = generateFAQSchema(section.items);
          jsx.push(<FAQSchema schemaFAQ={faqSchema} />);
        }
        break;

      default:
        break;
    }
  });

  /* Insert schema.org data conditionally (webpage) + everytime (organization/breadcrumbs/faq) */

  return jsx.map((component, index) => (
    <Fragment key={index}>{component}</Fragment>
  ));
};

export default StructuredData;
