import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";

const Strikethrough = ({ visible }) => (
  <svg
    aria-hidden="true"
    css={[
      css`
        opacity: ${visible ? 1 : 0};
      `
      // tw`w-full h-full absolute top-0 right-0 bottom-0 left-0 z-20 pointer-events-none`
    ]}
  >
    <line
      x1="0%"
      y1="100%"
      x2="100%"
      y2="0%"
      css={[
        css`
          stroke: black;
          fill: none;
        `
      ]}
    />
  </svg>
);

export default Strikethrough;

Strikethrough.defaultProps = {
  visible: false
};

Strikethrough.propTypes = {
  visible: PropTypes.bool
};
