import React, { useState } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { v4 as uuidv4 } from "uuid";
import { getGatsbyImageData } from "gatsby-source-sanity";

import { PortableText, Go, Image } from "~components";

import { ReactComponent as Arrow } from "~assets/icons/arrow.svg";

import { MEDIA_QUERIES } from "~utils/helpers";
import { GRID_GAP_REM } from "~components/_common/Grid/index.jsx";

const AccordionItem = styled.li`
  padding-top: 0.75rem;
`;

const AccordionButton = styled.button`
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: left;
`;

const AccordionHeading = styled.h3`
  margin-bottom: 1.25rem;

  ${MEDIA_QUERIES.desktop} {
    margin-bottom: 1.5rem;
  }
`;

const AccordionBody = `
  margin-bottom: 1.25rem;
  white-space: pre-line;
  width: 100%;

  ${MEDIA_QUERIES.desktop} {
    margin-bottom: 1.5rem;
  }
`;

const UnOrderedList = styled.ul`
  padding-left: 1.5ch;
  list-style: outside disc;

  p {
    display: inline;
  }
`;

const OrderedList = styled.ol`
  padding-left: 1.5ch;
  list-style: outside disc;

  p {
    display: inline;
  }
`;

const ListItem = styled.li`
  margin-bottom: 0.25rem;
`;

const index = ({ items, bodyHalf, _css }) => {
  const [itemKeys] = useState(items.map(() => uuidv4()));
  const [activeItem, setActiveItem] = useState(null);

  // todo: find a better way to replace one
  //       type of block or mark in a serializer

  const serializer = {
    types: {
      block: ({ children }) => <p className="b2">{children}</p>,
      altImage: (props) => {
        const fluidProps = getGatsbyImageData(
          props.node.asset._ref,
          { maxWidth: 800 },
          { projectId: `kq1t4t3g`, dataset: `production` }
        );
        return <Image image={fluidProps} alt={props.node.altText} />;
      }
    },
    marks: {
      strong: ({ children }) => <strong>{children}</strong>,
      em: ({ children }) => <em>{children}</em>,
      sup: ({ children }) => <sup>{children}</sup>,
      sub: ({ children }) => <sub>{children}</sub>,
      link: ({ children, mark }) => (
        <Go to={mark.href} newTab _css={{ textDecoration: `underline` }}>
          {children}
        </Go>
      )
    },
    list: ({ type, children }) => {
      if (type === `bullet`) {
        return <UnOrderedList>{children}</UnOrderedList>;
      }
      return <OrderedList>{children}</OrderedList>;
    },
    listItem: ({ children }) => (
      <ListItem>
        <p className="b2">{children}</p>
      </ListItem>
    )
  };

  return (
    <ul
      css={css`
        ${_css}
      `}
    >
      {items.map((item, itemIndex) => {
        const listItemKey = `accordion-list-item-${
          item?._key || itemKeys[itemIndex]
        }`;
        const buttonIsActive = activeItem === listItemKey;

        return (
          <AccordionItem
            key={listItemKey}
            css={css`
              border-top: solid 1px currentColor;
            `}
          >
            <AccordionButton
              type="button"
              onClick={() => setActiveItem(!buttonIsActive && listItemKey)}
            >
              <AccordionHeading className="b1">
                {item?.heading}
              </AccordionHeading>

              <Arrow
                css={css`
                  width: 20px;
                  height: 20px;

                  ${buttonIsActive && `transform: rotate(180deg);`}
                `}
                stroke="black"
              />
            </AccordionButton>

            {buttonIsActive &&
              (item?._rawText ? (
                <PortableText
                  _css={css`
                    ${AccordionBody}
                    ${bodyHalf &&
                    `  ${MEDIA_QUERIES.desktop} {
                      width: calc(((100% / 6) * 4) - ${GRID_GAP_REM / 2}rem);
                    }`}
                  `}
                  blocks={item?._rawText}
                  serializer={serializer}
                />
              ) : (
                <p
                  className="b2"
                  css={css`
                    ${AccordionBody}
                    ${bodyHalf &&
                    `  ${MEDIA_QUERIES.desktop} {
                        width: calc(((100% / 6) * 4) - ${GRID_GAP_REM / 2}rem);
                      }`}
                  `}
                >
                  {item?.text}
                </p>
              ))}
          </AccordionItem>
        );
      })}
    </ul>
  );
};

export default index;
