/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */

import React from "react";
import { graphql } from "gatsby";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { Grid, Image, Button, Go } from "~components";
import { MEDIA_QUERIES } from "~utils/helpers";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  ${MEDIA_QUERIES.desktop} {
    height: 45.83vw;
    flex-direction: row;
  }

  ${MEDIA_QUERIES.wide} {
    height: 660px;
  }
`;

const Figure = styled.figure`
  width: 100%;
  height: 100%;
  flex: 1;

  ${MEDIA_QUERIES.desktop} {
    width: 50vw;
  }
`;

const ArticleContainer = styled.article`
  width: 100%;
  padding: 3rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;

  ${MEDIA_QUERIES.desktop} {
    width: 50vw;
    padding: 3rem 0;
  }
`;

const Article = styled.article`
  width: 100%;

  ${MEDIA_QUERIES.desktop} {
    width: 50%;
  }
`;

const Heading = styled.h2`
  margin-bottom: 2.5rem;
  text-transform: uppercase;
`;

const Paragraph = styled.p`
  margin-bottom: 2.5rem;
`;

const TextAndImageSection = ({
  data: { backgroundColor, fontColor, heading, text, button, image, alignment }
}) => {
  const { isDesktop } = useBreakpoint();

  return (
    <Wrapper
      css={css`
        background: ${backgroundColor?.hex || `var(--color-white)`};
        color: ${fontColor?.hex || `var(--color-black)`};
      `}
    >
      <Figure
        css={css`
          ${MEDIA_QUERIES.desktop} {
            ${alignment === `right` && `order: 2;`}
          }
        `}
      >
        <Image
          css={css`
            width: 100%;
            height: 100%;
            position: relative;
            display: block;
            object-fit: cover;
          `}
          image={image}
        />
      </Figure>

      <ArticleContainer>
        <Article>
          <Heading className="h2">{heading}</Heading>

          <Paragraph className="b1">{text}</Paragraph>

          {button?.text && button?.link && (
            <Go
              to={button.link}
              _css={css`
                width: 100%;

                ${MEDIA_QUERIES.tablet} {
                  display: block;
                  width: max-content;
                  margin: auto;
                }
              `}
            >
              <Button text={button.text} />
            </Go>
          )}
        </Article>
      </ArticleContainer>

      {/* <Grid
        _css={css`
          padding-top: 3rem;
          padding-bottom: 3rem;
          flex: 1;
        `}
        half={isDesktop}
      >
        
      </Grid> */}
    </Wrapper>
  );
};

export default TextAndImageSection;

export const query = graphql`
  fragment TextAndImageSectionFragment on SanityTextAndImageSection {
    _type

    backgroundColor {
      hex
      title
    }

    fontColor {
      hex
      title
    }

    heading

    text

    button {
      text
      link
    }

    image {
      altText
      asset {
        originalFilename
        title
        description

        gatsbyImageData(
          width: 720
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
      mobileImage {
        asset {
          gatsbyImageData(
            width: 720
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }

    alignment
  }
`;
