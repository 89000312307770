/* eslint-disable no-underscore-dangle */

import React from "react";
import { graphql } from "gatsby";
import { css } from "@emotion/react";
import * as S from "~components/PageBuilder/sections";
import { capitalizeString } from "~utils/helpers";

/** ============================================================================
 * @component
 * Core manifest of available sections inherited from Sanity.
 */
const PageBuilder = ({ pagebuilder }) => {
  const { sections } = pagebuilder;

  const jsx = [];

  // todo: testing
  // jsx.push(<S.HomeBanner data={{}} />);

  sections.forEach((section, sectionIndex) => {
    if (Object.keys(section).length === 0) return;

    const sectionKey = `pagebuilder-section-${sectionIndex}`;
    const Section = S?.[capitalizeString(section?._type)];

    if (!Section) {
      jsx.push(<React.Fragment key={sectionKey} />);
    }

    jsx.push(
      <section
        key={sectionKey}
        css={[
          css`
            position: relative;
            background-color: ${section?.backgroundColor?.hex || `white`};
            color: ${section?.fontColor?.hex || `black`};
          `
        ]}
      >
        <Section data={section} />
      </section>
    );
  });

  return jsx;
};

export default PageBuilder;

export const query = graphql`
  fragment PageBuilderFragment on SanityPage {
    pagebuilder {
      sections {
        ...AddressListSectionFragment
        ...ContactFormSectionFragment
        ...FaqSectionFragment
        ...FlavorArchiveSectionFragment
        ...HalfAndHalfBannerFragment
        ...HeadingSectionFragment
        ...MapSectionFragment
        ...ProductCarouselSectionFragment
        ...ProductGridSectionFragment
        ...StickyBannerFragment
        ...TextAndImageSectionFragment
        ...ThreeColBannerFragment
        ...TextBannerFragment
        ...TextCarouselFragment
        ...TubBannerFragment
      }
    }
  }
`;
