/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */

import React, { useEffect, useRef, useState } from "react";
import { graphql } from "gatsby";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { useLocation } from "@reach/router";
import { FormEmail, Grid, Input, TextArea, PortableText } from "~components";
import { MEDIA_QUERIES } from "~utils/helpers";

//
// CSS

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  ${MEDIA_QUERIES.desktop} {
    min-height: 45.83vw;
    flex-direction: row;
  }
`;

const GridCSS = `
  padding-top: 3rem;
  padding-bottom: 3rem;
  flex: 1;
`;

const GridColumn = styled.article`
  grid-column: 1 / -1;
`;

const FormInputCSS = `
  width: 100%;
  margin-bottom: 0.75rem;
  border-radius: 1.5rem;
  border: none;
  background: var(--color-white);
  color: var(--color-black);

  &::-webkit-input-placeholder {
    color: var(--color-black);
  }

  &::-moz-placeholder {
    color: var(--color-black);
  }

  &:-ms-input-placeholder {
    color: var(--color-black);
  }

  &:-moz-placeholder {
    color: var(--color-black);
  }
`;

const FormInput = `
  ${FormInputCSS};
  height: 3rem;
  padding: 0.125rem 1.5rem 0;
`;

const FormTextArea = `
  ${FormInputCSS};
  height: 10rem;
  padding: 1rem 1.5rem;
`;

const FormSubmit = `
  ${FormInputCSS};
  height: 3rem;
  padding: 0.125rem 1.5rem 0;
  cursor: pointer;
  text-transform: uppercase;
  background: var(--color-black);
  color: var(--color-white);

  ${MEDIA_QUERIES.hoverable} {
    &:hover {
      background: var(--color-white);
      color: var(--color-black);
    }
  }
`;

//
// JSX

const SECTION_HASH = `contact-form`;

const ContactFormSection = ({
  data: {
    backgroundColorLeft,
    backgroundColorRight,
    fontColor,
    heading,
    _rawText
  }
}) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const { isDesktop } = useBreakpoint();
  const location = useLocation();

  const ref = useRef();

  const [shouldScroll, setShouldScroll] = useState(false);

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setShouldScroll(location?.hash?.includes(SECTION_HASH));
  }, [location]);

  useEffect(() => {
    if (typeof window === `undefined` || !ref?.current || !shouldScroll) {
      return;
    }

    const { y } = ref.current.getBoundingClientRect();

    if (y === 0) {
      return;
    }

    setTimeout(() => {
      window.scrollTo({
        x: 0,
        top: y,
        behavior: `smooth`
      });
    }, 500);
  }, [ref, shouldScroll]);

  // ---------------------------------------------------------------------------
  // render

  return (
    <Wrapper id={SECTION_HASH} ref={ref}>
      <Grid
        _css={css`
          ${GridCSS};
          background: ${backgroundColorLeft?.hex || `var(--color-white)`};
          color: ${fontColor?.hex || `var(--color-black)`};
        `}
        half={isDesktop}
      >
        <GridColumn>
          <h2 className="h2">{heading}</h2>
          <PortableText
            blocks={_rawText}
            _css={css`
              margin-top: 2rem;
              white-space: pre-wrap;
            `}
          />
        </GridColumn>
      </Grid>

      <Grid
        _css={css`
          ${GridCSS};
          background: ${backgroundColorRight?.hex || `var(--color-white)`};
        `}
        half={isDesktop}
      >
        <GridColumn>
          <FormEmail
            css={css`
              position: relative;
              margin-top: 1.5rem;
            `}
          >
            <Input
              className="b1"
              name="fullName"
              placeholder="Full Name*"
              required
              type="text"
              _css={css`
                ${FormInput}
              `}
            />

            <Input
              className="b1"
              name="email"
              placeholder="Enter email*"
              required
              type="email"
              _css={css`
                ${FormInput}
              `}
            />

            <Input
              className="b1"
              name="subject"
              placeholder="Subject*"
              required
              type="text"
              _css={css`
                ${FormInput}
              `}
            />

            <TextArea
              className="b1"
              name="message"
              placeholder="Message*"
              required
              type="text"
              _css={css`
                ${FormTextArea}
              `}
            />

            <Input
              className="button"
              name="submit"
              type="submit"
              _css={css`
                ${FormSubmit}
              `}
            />
          </FormEmail>
        </GridColumn>
      </Grid>
    </Wrapper>
  );
};

export default ContactFormSection;

export const query = graphql`
  fragment ContactFormSectionFragment on SanityContactFormSection {
    _type

    backgroundColorLeft {
      hex
      title
    }

    backgroundColorRight {
      hex
      title
    }

    fontColor {
      hex
      title
    }

    heading
    _rawText
  }
`;
