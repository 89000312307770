import React from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { Go } from "~components";
import { useApp, useSanityGlobals } from "~hooks";
import { ReactComponent as Spoon } from "~assets/icons/spoon.svg";
import { MEDIA_QUERIES } from "~utils/helpers";

//
// CSS

const Wrapper = styled.nav`
  transition: opacity 0.3s var(--cubic-easing);

  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 90;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-black);
  overflow-y: scroll;
`;

const List = styled.ul`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: uppercase;
  text-align: center;

  & > li {
    margin-bottom: 0.5rem;
  }

  & > li:last-child {
    margin-bottom: 0;
  }
`;

const ListItem = styled.li`
  width: max-content;
  position: relative;
  color: var(--color-white);
  transition: color 0.3s var(--cubic-easing);
`;

/** ============================================================================
 * @component
 * Global nav.
 */
const Menu = () => {
  const { menuActive, setMenuActive } = useApp();
  const { sanityGlobals } = useSanityGlobals();
  const { isDesktop } = useBreakpoint();

  return (
    <Wrapper
      aria-hidden={!menuActive}
      css={css`
        opacity: ${menuActive ? 1 : 0};
        pointer-events: ${menuActive ? `all` : `none`};
      `}
    >
      <List>
        {sanityGlobals.menuLinks.map((link, linkIndex) => {
          let spoonStyles = `
          left: -8vw;
          transform: rotate(-68deg);

          ${MEDIA_QUERIES.desktop} {
            left: -5vw;
          }
          `;

          if (linkIndex % 2 !== 0) {
            spoonStyles = `
            right: -8vw;
            transform: rotate(68deg);
            
            ${MEDIA_QUERIES.desktop} {
              right: -5vw;
            }
            `;
          }

          return (
            <ListItem
              key={`${link._key}-menu-item`}
              className={isDesktop ? `d3` : `h2`}
              css={css`
              &:hover, &:active {
                color: ${link?.hoverColour?.hex};\
              }

              &:hover svg, &:active svg {
                opacity: 1;
              }
            `}
            >
              <Go to={link.url} onClick={() => setMenuActive(false)}>
                <span
                  css={css`
                    user-select: none;
                  `}
                >
                  {link.text}
                </span>
              </Go>

              <Spoon
                css={css`
                  width: 2.176vw;
                  height: 11vw;
                  position: absolute;
                  fill: ${link?.hoverColour?.hex};
                  stroke: black;
                  top: -1.11vw;
                  opacity: 0;
                  transition: color 0.3s var(--cubic-easing),
                    opacity 0.3s var(--cubic-easing);
                  ${spoonStyles}

                  ${MEDIA_QUERIES.desktop} {
                    width: 1.69vw;
                    height: 8.57vw;
                  }
                `}
              />
            </ListItem>
          );
        })}
      </List>
    </Wrapper>
  );
};
export default Menu;
