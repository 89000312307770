import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

const MODEL_URL = `/webgl/tub/milk_choc_chip.gltf`;

const TubGLTF = () => {
  const group = useRef();

  const { nodes, materials } = useGLTF(MODEL_URL);

  //

  const adjustedTubScale = [1.05, 1.05, 1.175];

  return (
    <group ref={group} dispose={null}>
      <mesh
        geometry={nodes.MILK_CHOC_CHIP_LID.geometry}
        material={materials[`Material #12`]}
        position={[0, 0.066, 0]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.97}
      />
      <mesh
        geometry={nodes.MILK_CHOC_CHIP_TUB.geometry}
        material={materials[`Material #13`]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={adjustedTubScale}
      />
    </group>
  );
};

useGLTF.preload(MODEL_URL);

export default TubGLTF;
