// /* eslint-disable react/prop-types */

import React, { useEffect, Children, createElement } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";

const Form = ({ children, defaultValues, onSubmit, _css }) => {
  const {
    formState: {
      clearErrors,
      dirtyFields,
      errors,
      getValues,
      isDirty,
      isSubmitSuccessful,
      isSubmitted,
      isSubmitting,
      isValid,
      isValidating,
      setFocus,
      submitCount,
      touchedFields
    },
    handleSubmit,
    register,
    reset
  } = useForm({ defaultValues });

  return (
    <form
      onSubmit={handleSubmit((data, event) =>
        onSubmit({
          clearErrors,
          data,
          dirtyFields,
          event,
          getValues,
          isDirty,
          isSubmitSuccessful,
          isSubmitted,
          isSubmitting,
          isValid,
          isValidating,
          reset,
          submitCount,
          touchedFields
        })
      )}
      css={_css}
    >
      {Children.map(children, (child) =>
        child.props.name
          ? createElement(child.type, {
              ...{
                ...child.props,
                register,
                error: errors[child.props.name],
                key: child.props.name
              }
            })
          : child
      )}
    </form>
  );
};

export default Form;

Form.propTypes = {
  defaultValues: PropTypes.shape({}),
  onSubmit: PropTypes.func.isRequired,
  _css: PropTypes.shape({})
};

Form.defaultProps = {
  defaultValues: {},
  _css: []
};
