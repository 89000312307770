import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { css } from "@emotion/react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

import { MEDIA_QUERIES } from "~utils/helpers";

const Button = ({ _css, className, color, onClick, text, type }) => {
  const [hovering, setHovering] = useState(false);
  const { isDesktop } = useBreakpoint();

  //

  let theme = {};

  switch (color) {
    case `light`:
      if (type === `big`) {
        theme = {
          background: `transparent`,
          color: `var(--color-white)`,
          click: `var(--color-light-grey)`
        };
      } else {
        theme = {
          background: `var(--color-white)`,
          color: `var(--color-black)`,
          click: `var(--color-light-grey)`
        };
      }

      break;

    default:
      if (type === `big`) {
        theme = {
          background: `transparent`,
          color: `var(--color-black)`,
          click: `var(--color-dark-grey)`
        };
      } else {
        theme = {
          background: `var(--color-black)`,
          color: `var(--color-white)`,
          click: `var(--color-dark-grey)`
        };
      }
  }

  //

  let padding = {
    mobile: {
      top: `0.75rem`,
      bottom: `1rem`,
      left: `2.25rem`,
      right: `2.25rem`
    },
    desktop: {
      top: `0.75rem`,
      bottom: `1rem`,
      left: `2.25rem`,
      right: `2.25rem`
    }
  };

  let textClass = `button`;

  if (type === `big`) {
    padding = {
      mobile: {
        top: `1.125rem`,
        bottom: `1.25rem`,
        left: `1.8125rem`,
        right: `1.8125rem`
      },
      desktop: {
        top: `3.125rem`,
        bottom: `3.125rem`,
        left: `3.75rem`,
        right: `3.75rem`
      }
    };

    textClass = `h2`;
  }

  //

  return (
    <button
      type="button"
      className={className}
      css={css`
        position: inline-block;
        width: ${type === `big` ? `auto` : `100%`};
        max-width: ${type === `big` && `162px`};
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        padding-top: ${padding.mobile.top};
        padding-bottom: ${padding.mobile.bottom};
        padding-left: ${padding.mobile.left};
        padding-right: ${padding.mobile.right};
        border-radius: 999px;
        text-transform: uppercase;
        transition: transform 0.3s var(--cubic-easing),
          color 0.3s var(--cubic-easing), background 0.3s var(--cubic-easing),
          opacity 0.3s var(--cubic-easing);
        background-color: ${theme.background};
        color: ${theme.color};
        ${type === `big` && `border: 1px solid ${theme.color};`}

        ${MEDIA_QUERIES.desktop} {
          max-width: ${type === `big` && `367px`};
          padding-top: ${padding.desktop.top};
          padding-bottom: ${padding.desktop.bottom};
          padding-left: ${padding.desktop.left};
          padding-right: ${padding.desktop.right};
        }

        &.hover {
          ${type === `big` &&
          `background-color: ${
            color === `light` ? `var(--color-white)` : `var(--color-black)`
          };
              color: ${
                color === `light` ? `var(--color-black)` : `var(--color-white)`
              };
              `}
        }

        ${MEDIA_QUERIES.hoverable} {
          &:hover {
            ${type === `big` &&
            `background-color: ${
              color === `light` ? `var(--color-white)` : `var(--color-black)`
            };
                color: ${
                  color === `light`
                    ? `var(--color-black)`
                    : `var(--color-white)`
                };
                `}
            transform: scale(110%);
          }
        }

        &:focus,
        &:active {
          backgroud-color: red;
        }

        ${_css};
      `}
      onClick={onClick}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <p
        className={isDesktop ? textClass : `button`}
        css={css`
          color: inherit;
        `}
      >
        {text}
      </p>
    </button>
  );
};

Button.defaultProps = {
  _css: {},
  onClick: () => {},
  text: `Button`,
  color: `dark`,
  type: `normal`
};

Button.propTypes = {
  _css: PropTypes.shape({}),
  onClick: PropTypes.func,
  text: PropTypes.string,
  color: PropTypes.string,
  type: PropTypes.string
};

export default Button;
