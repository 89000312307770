import React from "react";
import { createPortal } from "react-dom";

const DOCUMENT_ROOT =
  typeof document !== `undefined` ? document.getElementById(`___gatsby`) : null;

const WebPageSchema = ({
  buildTime,
  author,
  mainUrl,
  title,
  description,
  image,
  language,
  name,
  year
}) => {
  const schemaOrgWebPage = {
    "@context": `http://schema.org`,
    "@type": `WebPage`,
    url: mainUrl,
    headline: title,
    inLanguage: language,
    mainEntityOfPage: mainUrl,
    description,
    name,
    author: {
      "@type": `Person`,
      name: author
    },
    copyrightHolder: {
      "@type": `Person`,
      name: author
    },
    copyrightYear: year,
    creator: {
      "@type": `Person`,
      name: author
    },
    publisher: {
      "@type": `Person`,
      name: author
    },
    datePublished: `2019-01-18T10:30:00+01:00`,
    dateModified: buildTime,
    image: {
      "@type": `ImageObject`,
      url: image
    }
  };

  if (DOCUMENT_ROOT) {
    return createPortal(
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgWebPage)}
      </script>,
      DOCUMENT_ROOT
    );
  }

  return null;
};

export default WebPageSchema;
