import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

const MODEL_URL = `/webgl/box/mint_choc_pop_002.gltf`;

const BoxGLTF = () => {
  const group = useRef();
  const { nodes, materials } = useGLTF(MODEL_URL);

  if (!nodes?.Cube?.geometry) {
    return <></>;
  }

  return (
    <group ref={group} dispose={null}>
      <mesh
        geometry={nodes.Cube.geometry}
        material={materials[`Material.001`]}
        position={[0, 0, 0]}
        scale={[0.04, -0.06, 0.02]}
      />
    </group>
  );
};

useGLTF.preload(MODEL_URL);

export default BoxGLTF;
