/* eslint-disable jsx-a11y/no-static-element-interactions */

import React from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Go, Grid, Button } from "~components";
import { MEDIA_QUERIES } from "~utils/helpers";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

import { ReactComponent as Sticker } from "~assets/stickers/now-in-a-four-pack.svg";

const Container = styled.section`
  transition: background-color 0.3s var(--cubic-easing);

  width: 100vw;
  position: relative;
  display: block;
  z-index: 10;
`;

const Heading = styled.h2`
  color: var(--color-white);
  text-align: center;

  ${MEDIA_QUERIES.desktop} {
    text-align: left;
    white-space: pre-line;
    padding-bottom: 48px;
  }
`;

const ModelWrapper = styled.div`
  grid-column: span 12 / span 12;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  // new
  padding-top: 15vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 100%;

  ${MEDIA_QUERIES.tablet} {
    height: 100vw;
    padding-top: 20vh;
  }

  ${MEDIA_QUERIES.desktop} {
    padding-top: 0;
    grid-column: span 7 / span 7;
    height: 70%;
    position: relative;
  }
`;

const ContentWrapper = styled.article`
  z-index: 10;
  grid-column: span 12 / span 12;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  pointer-events: auto;
  margin: 0;

  // new
  padding: 72px 0 32px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  ${MEDIA_QUERIES.tablet} {
    height: auto;
    padding-bottom: 0;
  }

  ${MEDIA_QUERIES.desktop} {
    height: auto;
    max-height: none;
    position: relative;
    right: auto;
    bottom: auto;
    left: auto;
    margin: auto 0;
    padding: 0;
    align-items: unset;
  }
`;

const CollectionBannerWrapper = ({
  background,
  children,
  heading,
  ctaText,
  reverse = false,
  url
}) => {
  const { isMobile, isDesktop, isTablet } = useBreakpoint();

  const isTabletOnly = isTablet && !isDesktop && !isMobile;

  return (
    <Container
      css={css`
        background: ${background};
      `}
    >
      <div
        css={css`
          width: 100%;
          height: 150vh;
          position: relative;
          backface-visibility: hidden;
          clip: rect(auto, auto, auto, auto);
          clip-path: inset(0 0 0 0);
        `}
      >
        <div
          css={css`
            transform: translate3d(0, 0, 0);
            backface-visibility: hidden;

            width: 100%;
            height: 100vh;
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            pointer-events: none;

            // new
            align-items: flex-end;
          `}
        >
          <Grid
            node="article"
            _css={css`
              height: 100%;
              z-index: 20;
              display: flex;
              flex-direction: column;

              ${MEDIA_QUERIES.desktop} {
                display: grid;
              }
            `}
          >
            <ContentWrapper
              css={css`
                order: 1;

                ${MEDIA_QUERIES.desktop} {
                  order: ${reverse ? 2 : 1};
                  grid-column: ${reverse
                    ? `span 5 / span 5`
                    : `span 6 / span 6`};
                  grid-column-start: ${reverse ? `8` : `1`};
                }
              `}
            >
              <Heading className={isTabletOnly ? `h1` : `d3`}>
                {heading}
              </Heading>

              <Go
                to={url}
                _css={css`
                  width: 100%;

                  ${MEDIA_QUERIES.tablet} {
                    width: fit-content;
                  }
                `}
              >
                <Button
                  text={ctaText}
                  _css={css`
                    width: 100%;
                    margin: 0;

                    ${MEDIA_QUERIES.tablet} {
                      width: 200px;
                      margin: 24px auto 0;
                    }

                    ${MEDIA_QUERIES.desktop} {
                      width: max-content;
                      margin: 0;
                    }
                  `}
                />
              </Go>
              {/* desktop */}
              <Sticker
                css={css`
                  display: none;
                  position: absolute;
                  bottom: -170px;
                  right: 0;
                  width: 220px;
                  ${MEDIA_QUERIES.desktop} {
                    display: block;
                  }
                `}
                fill="var(--color-white)"
              />
            </ContentWrapper>

            <ModelWrapper
              css={css`
                order: 2;

                ${MEDIA_QUERIES.desktop} {
                  order: ${reverse ? 1 : 2};
                }
              `}
            >
              {children}
            </ModelWrapper>
          </Grid>
        </div>
      </div>
    </Container>
  );
};

export default CollectionBannerWrapper;
