/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { useGoogleMaps } from "~hooks";

// import { Marker } from "~components";

const index = ({
  stockists,
  setStockists,
  selectedStockist,
  setSelectedStockist,
  userLocation
}) => {
  const { ref, map, google } = useGoogleMaps(
    process.env.GATSBY_GOOGLE_MAP_API,
    {
      center: { lat: -26.5984274, lng: 134.0060596 },
      zoom: 4,
      clickableIcons: false,
      fullscreenControl: false,
      mapTypeControl: false,
      rotateControl: false,
      scaleControl: false,
      streetViewControl: false,
      zoomControl: true
    }
  );

  const [markers, setMarkers] = useState(null);

  const initializeMarkers = () => {
    const infowindow = new google.maps.InfoWindow();

    // add markers to mapp
    const stockistMarkers = stockists.map((stockist) => {
      const marker = new google.maps.Marker({
        position: stockist.location,
        map
      });

      // adding infowindow
      marker.addListener(`click`, () => {
        infowindow.setContent(
          `<p className="b2" style="color: black;">${stockist.name}<br />${stockist.address}</p>`
        );
        infowindow.setPosition(stockist.location);
        infowindow.setOptions({ pixelOffset: new google.maps.Size(0, -40) });
        infowindow.open(map);
        setSelectedStockist(stockist);
      });

      return marker;
    });

    setMarkers(stockistMarkers);
  };

  const getDistanceMatrix = (request) => {
    const distanceMatrixService = new google.maps.DistanceMatrixService();

    return new Promise((resolve, reject) => {
      const callback = (response, status) => {
        if (status === google.maps.DistanceMatrixStatus.OK) {
          resolve(response);
        } else {
          reject(response);
        }
      };
      distanceMatrixService.getDistanceMatrix(request, callback);
    });
  };

  const calculateDistances = async (origin, stores) => {
    // Retrieve the distances of each store from the origin
    // The returned list will be in the same order as the destinations list
    const response = await getDistanceMatrix({
      origins: [origin],
      destinations: stores.map((store) => store.location),
      travelMode: google.maps.TravelMode.DRIVING,
      unitSystem: google.maps.UnitSystem.METRIC
    });

    response.rows[0].elements.forEach(({ distance }, i) => {
      stores[i].distance = distance;
    });

    stores.sort((a, b) => a.distance.value - b.distance.value);
    setStockists([...stores]);
  };

  const initAutocompleteWidget = () => {
    // Add search bar for auto-complete
    // Build and add the search bar
    const placesAutoCompleteInputElement = document.getElementById(`pac-input`);
    // const placesAutoCompleteCardElement = document.getElementById(`pac-card`);
    // const placesAutoCompleteInputElement =
    //   placesAutoCompleteCardElement.querySelector(`input`);

    const options = {
      types: [`address`],
      componentRestrictions: { country: `au` },
      map
    };

    // Make the search bar into a Places Autocomplete search bar and select
    // which detail fields should be returned about the place that
    // the user selects from the suggestions.
    const autocomplete = new google.maps.places.Autocomplete(
      placesAutoCompleteInputElement,
      options
    );
    autocomplete.setFields([`address_components`, `geometry`, `name`]);
    map.addListener(`bounds_changed`, () => {
      autocomplete.setBounds(map.getBounds());
    });

    // Respond when a user selects an address
    // Set the origin point when the user selects an address
    const originMarker = new google.maps.Marker({ map });
    originMarker.setVisible(false);
    let originLocation = map.getCenter();

    autocomplete.addListener(`place_changed`, async () => {
      originMarker.setVisible(false);
      originLocation = map.getCenter();
      const place = autocomplete.getPlace();

      if (!place.geometry) {
        return;
      }
      // Recenter the map to the selected address
      originLocation = place.geometry.location;
      map.setCenter(originLocation);
      map.setZoom(15);
      originMarker.setPosition(originLocation);
      originMarker.setVisible(true);

      // Use the selected address as the origin to calculate distances
      // to each of the store locations
      await calculateDistances(originLocation, stockists);
      // renderStoresPanel();
    });
  };

  useEffect(() => {
    if (map) {
      initializeMarkers();
    }
  }, [map]);

  useEffect(() => {
    if (markers?.[0]) {
      initAutocompleteWidget();
    }
  }, [markers]);

  useEffect(() => {
    if (!map) {
      return;
    }

    if (!selectedStockist) {
      map.setZoom(4);
      map.panTo({ lat: -26.5984274, lng: 134.0060596 });
    } else {
      if (map.zoom !== 18) {
        map.setZoom(18);
      }

      map.panTo(selectedStockist?.location);
    }
  }, [selectedStockist]);

  return <div ref={ref} style={{ width: `100%`, height: `100%` }} />;
};

export default index;
