import React from "react";
import { createPortal } from "react-dom";

const DOCUMENT_ROOT =
  typeof document !== `undefined` ? document.getElementById(`___gatsby`) : null;

const FAQSchema = ({ schemaFAQ }) => {
  if (DOCUMENT_ROOT) {
    return createPortal(
      <script type="application/ld+json">{JSON.stringify(schemaFAQ)}</script>,
      DOCUMENT_ROOT
    );
  }

  return null;
};

export default FAQSchema;
