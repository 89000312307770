import React from "react";
import { createPortal } from "react-dom";

const DOCUMENT_ROOT =
  typeof document !== `undefined` ? document.getElementById(`___gatsby`) : null;

const OraganizationSchema = ({ mainUrl, name, facebook, instagram }) => {
  const organizationSchema = {
    "@context": `https://schema.org`,
    "@type": `Organization`,
    name,
    url: mainUrl,
    logo: `${mainUrl}/favicon-32x32.png`,
    sameAs: [facebook, instagram] || []
  };

  if (DOCUMENT_ROOT) {
    return createPortal(
      <script type="application/ld+json">
        {JSON.stringify(organizationSchema)}
      </script>,
      DOCUMENT_ROOT
    );
  }

  return null;
};

export default OraganizationSchema;
