/* eslint-disable react/jsx-props-no-spreading */
// https://swiperjs.com/react

/* eslint-disable react/prop-types */

import React, { useRef, useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import { css } from "@emotion/react";
import { v4 as uuidv4 } from "uuid";
import SwiperCore, { Autoplay, Pagination, EffectFade } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useWindowDimensions } from "~hooks";
import { Grid } from "~components";
import {
  GRID_GAP_PX,
  GRID_COLUMNS,
  GRID_PADDING_PX,
  GRID_MAX_WIDTH_PX
} from "~components/_common/Grid/index.jsx";

import "swiper/css/bundle";

const SwiperCarousel = ({
  className,
  id,
  gridwrap,
  loop,
  options,
  nav,
  slides,
  slideColumnWidth
}) => {
  SwiperCore.use([Autoplay, Pagination, EffectFade]);

  // ---------------------------------------------------------------------------
  // context / ref / state

  const swiper = useRef(null);

  const [containerWidthCSS, setContainerWidthCSS] = useState(`auto`);
  const [current, setCurrent] = useState(1);
  const [swiperOptions, setSwiperOptions] = useState(options);
  const [slideKeys, setSlideKeys] = useState([]);

  const windowSize = useWindowDimensions();

  // ---------------------------------------------------------------------------
  // methods

  const next = () => {
    if (swiper?.current?.swiper?.slideNext) {
      swiper.current.swiper.slideNext();
    }
  };

  const prev = () => {
    if (swiper?.current?.swiper?.slidePrev) {
      swiper.current.swiper.slidePrev();
    }
  };

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    const keys = slides.map(() => uuidv4());

    setSlideKeys(keys);
  }, [slides]);

  useEffect(() => {
    if (!slideColumnWidth) {
      return;
    }

    options = {
      ...options,
      spaceBetween: GRID_GAP_PX,
      slidesPerView: GRID_COLUMNS / slideColumnWidth
    };

    let widthCSS = `calc(100% - ${GRID_PADDING_PX}px)`;

    if (windowSize?.width > GRID_MAX_WIDTH_PX) {
      widthCSS = `calc(100% - ${GRID_PADDING_PX}px - ${
        windowSize?.width - GRID_MAX_WIDTH_PX
      }px)`;
    }

    setSwiperOptions(options);
    setContainerWidthCSS(widthCSS);
  }, [options, slideColumnWidth, windowSize?.width]);

  // ---------------------------------------------------------------------------
  // render

  // if (!slides?.[0] || !slideKeys?.[0]) {
  //   return <></>;
  // }

  const swiperJSX = (
    <>
      <Swiper
        id={id}
        ref={swiper}
        css={css`
          width: ${containerWidthCSS};
          overflow: hidden;

          .swiper-slide {
            height: auto; // for consistent card heights
          }

          .swiper-pagination-bullet,
          .swiper-pagination-bullet-active {
            background-color: var(--color-white);
          }
        `}
        className={className}
        loop={loop}
        onSlideChange={({ realIndex }) => {
          setCurrent(realIndex);
        }}
        {...swiperOptions}
      >
        {slides.map((slide, slideIndex) => (
          <SwiperSlide key={slideKeys[slideIndex]}>{slide}</SwiperSlide>
        ))}
      </Swiper>

      {nav &&
        nav({
          current,
          next,
          prev
        })}
    </>
  );

  if (gridwrap) {
    return (
      <Grid>
        <div
          css={
            [
              // tw`col-span-12`
            ]
          }
        >
          {swiperJSX}
        </div>
      </Grid>
    );
  }

  return swiperJSX;
};

SwiperCarousel.defaultProps = {
  _css: [],
  id: `default-swiper`,
  gridwrap: false,
  loop: true,
  options: {
    spaceBetween: 0,
    slidesPerView: 1
  },
  slides: null,
  slideColumnWidth: null
};
SwiperCarousel.propTypes = {
  _css: PropTypes.shape({}),
  id: PropTypes.string,
  gridwrap: PropTypes.bool,
  loop: PropTypes.bool,
  options: PropTypes.shape({}),
  slides: PropTypes.arrayOf(PropTypes.shape({})),
  slideColumnWidth: PropTypes.number
};

export default SwiperCarousel;
