/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */

import React from "react";
import { graphql } from "gatsby";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

import { Grid, Image } from "~components";

import { MEDIA_QUERIES } from "~utils/helpers";

const GridCss = `
  padding-top: 3rem;
  padding-bottom: 3.75rem;
  z-index: 10;

  ${MEDIA_QUERIES.desktop} {
    height: 52.6vw;
    padding-top: 2.25rem;
  }
`;

const Heading = styled.h2`
  grid-column: 1 / -1;
  text-transform: uppercase;
  margin-bottom: 2.5rem;

  ${MEDIA_QUERIES.desktop} {
    grid-column: 1 / span 5;
  }
`;

const List = styled.ul`
  grid-column: 1 / -1;

  ${MEDIA_QUERIES.desktop} {
    grid-column: 8 / span 5;
  }
`;

const ListItem = styled.li`
  positon: relative;
  display: flex;
  padding: 0.7rem 0;
`;

const AddressListSection = ({
  data: { backgroundColor, fontColor, heading, addressList, backgroundImage }
}) => {
  const { isDesktop } = useBreakpoint();

  return (
    <>
      <Grid
        _css={css`
          color: ${fontColor?.hex || `var(--color-black)`};
          background-color: ${backgroundColor?.hex || `var(--color-white)`};

          ${MEDIA_QUERIES.desktop} {
            background-color: transparent;
          }
          ${GridCss}
        `}
      >
        <Heading className={isDesktop ? `h1` : `h2`}>{heading}</Heading>

        <List>
          {addressList.map((address) => (
            <ListItem
              key={address._key}
              css={css`
                border-top: 1px solid ${fontColor?.hex || `var(--color-black)`};
              `}
            >
              <h3
                css={css`
                  flex: 1;
                `}
                className={isDesktop ? `b1` : `b2`}
              >
                {address.state}
              </h3>

              <div
                css={css`
                  flex: 2;
                `}
                className={isDesktop ? `b1` : `b2`}
              >
                <p>{address.name}</p>
                <p>{address.info}</p>
              </div>
            </ListItem>
          ))}
        </List>
      </Grid>
      <Image
        image={backgroundImage}
        css={css`
          width: 100%;
          position: ${isDesktop ? `absolute` : `relative`};
          bottom: 0;
          left: 0;
        `}
        contain
      />
    </>
  );
};

export default AddressListSection;

export const query = graphql`
  fragment AddressListSectionFragment on SanityAddressListSection {
    _type

    backgroundColor {
      hex
      title
    }

    fontColor {
      hex
      title
    }

    heading

    addressList {
      _key
      state
      name
      info
    }

    backgroundImage {
      altText
      asset {
        gatsbyImageData(
          width: 1440
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
      mobileImage {
        asset {
          gatsbyImageData(
            width: 720
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  }
`;
